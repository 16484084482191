import { useToast, VStack } from "@chakra-ui/react"
import startCase from "lodash/startCase"
import React, { useEffect, useState } from "react"
import { stripHtml } from "string-strip-html"
import { CheckEmailVerification } from "./components"
import { ContextProvider } from "./context"
import { AlertTypes, useAlertsUpdateSubscription, useMeQuery } from "./graphql"
import { SplashPage } from "./pages/Splash.page"
import { RootRouter } from "./router/Root.router"
import { UnauthorizedRouter } from "./router/Unauthorized.router"

export const App: React.FC = () => {
	const [{ data, fetching }] = useMeQuery()

	const [{ data: alertsData }] = useAlertsUpdateSubscription()
	const toast = useToast()

	const [toastsShown, setToastsShown] = useState<string[]>([])

	useEffect(() => {
		if (alertsData?.alertsUpdate && !toastsShown.includes(alertsData.alertsUpdate._id) && alertsData.alertsUpdate.type !== AlertTypes.RestrictedEntry) {
			setToastsShown((prev) => [...prev, alertsData.alertsUpdate._id])

			toast({
				title: startCase(alertsData.alertsUpdate.type),
				description: stripHtml(alertsData.alertsUpdate.emailMessage).result,
				status: "warning",
				position: "top-right",
				duration: null,
				isClosable: true,
			})
		}

		return () => {
			setToastsShown([])
		}
	}, [alertsData])

	if (fetching) {
		return <SplashPage />
	}

	return (
		<ContextProvider>
			<VStack w="full" align="stretch">
				<CheckEmailVerification />
				{data?.me ? (
					<VStack w="full">
						<RootRouter />
					</VStack>
				) : (
					<UnauthorizedRouter />
				)}
			</VStack>
		</ContextProvider>
	)
}
