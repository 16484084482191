import { Flex, Link, ListItem } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedUserLocationRecordFragment, useUserCurrentUserRoleQuery } from "../../graphql"
import { getDurationInWords, transformDateTime } from "../../utils"
import { Stat } from "../common"

export type ZonedUserLocationListItemProps = {
	userLocationRecord: ExtendedUserLocationRecordFragment
}

export const ZonedUserLocationListItem: React.FC<ZonedUserLocationListItemProps> = ({ userLocationRecord }) => {
	const [{ data }] = useUserCurrentUserRoleQuery({ variables: { userId: userLocationRecord.userId } })

	return (
		<ListItem bgColor={userLocationRecord.isRestricted ? "red.100" : "transparent"}>
			<Flex justify="center" align="center">
				<Stat
					label="User"
					value={
						<Link as={NavLink} to={`/users/${userLocationRecord.userId}`} w="28">
							{userLocationRecord.user.name}
						</Link>
					}
				/>
				{data?.userCurrentUserRole?.userRoleGroup.department && <Stat label="Department" value={data.userCurrentUserRole.userRoleGroup.department.label.name || ""} />}
				<Stat label="Since" value={transformDateTime(new Date(userLocationRecord.startAt))} />
				<Stat label="Until" value={transformDateTime(new Date(userLocationRecord.endAt))} />
				<Stat label="Status" value={userLocationRecord.status} />
				<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(userLocationRecord.endAt), new Date(userLocationRecord.startAt)))} />
			</Flex>
		</ListItem>
	)
}
