import { Center, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react"
import React, { useEffect, useMemo } from "react"
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom"
import { UserCurrentLocation, UserPastLocations, UserPermissionsGroups, UserProfile, UserRoleDetails, UserTag, UserTagDetails, VisitorDetails } from "../../components"
import { Header } from "../../components/common/Header"
import { UserActiveAttendance, UserPastAttendances } from "../../components/userAttendance"
import { AssetTagDetail } from "../../components/users/AssetTagDetail"
import { useMeQuery, UserRoleTypes, useUserByIdQuery, useUserLocationRecordUpdateSubscription } from "../../graphql"
import { checkForPermissions } from "../../utils"

const UserPage: React.FC = () => {
	const { userId = "" } = useParams<{ userId: string }>()

	const [{ data: meData }] = useMeQuery()

	const navigate = useNavigate()

	useEffect(() => {
		if (meData?.me?._id === userId) {
			navigate("/profile", { replace: true })
		}
	}, [meData, userId])

	const [{ data, fetching, error }] = useUserByIdQuery({ variables: { userId }, pause: !userId || meData?.me?._id === userId })

	useUserLocationRecordUpdateSubscription({ variables: { userId }, pause: !userId })

	const canReadUserPermissionsGroups = useMemo(() => {
		if (!data?.userById || !meData?.me) return true

		if (data.userById.type.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(meData.me, "permissions/read-admin-user-permissions-group")[0]
		}

		if (data.userById.type.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(meData.me, "permissions/read-short-term-user-permissions-group")[0]
		}

		return checkForPermissions(meData.me, "permissions/read-working-user-permissions-group")[0]
	}, [data, meData])

	const { pathname } = useLocation()

	return (
		<VStack w="full" align="stretch" p="8" spacing={4}>
			<Header title="User" />
			<VStack w="full" align="stretch">
				{fetching ? (
					<Center w="full" py="4">
						<VStack w="full" color="grayscale.label">
							<Text fontSize="sm">Loading user</Text>
							<Spinner size="sm" />
						</VStack>
					</Center>
				) : error ? (
					<Center py="4">
						<Text fontSize="sm" fontWeight="semibold" color="error.500">
							{error.message.replace("[GraphQL] ", "")}
						</Text>
					</Center>
				) : data?.userById ? (
					<Stack w="full" direction={{ base: "column", xl: "row" }}>
						<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
							<UserTag user={data.userById} allowAvatarChange />

							{data.userById.type.roleType !== UserRoleTypes.Admin && <UserTagDetails user={data.userById} />}
							{data.userById.siteId === "65957f437404862120b26919" && <AssetTagDetail user={data.userById} />}
							<UserProfile user={data.userById} />
							{data.userById.type.label.name === "Visitor" && <VisitorDetails user={data.userById} />}
							{data.userById.type.roleType !== UserRoleTypes.Admin && <UserRoleDetails user={data.userById} />}

							{canReadUserPermissionsGroups && <UserPermissionsGroups user={data.userById} />}
						</VStack>
						{data.userById.type.roleType !== UserRoleTypes.Admin && (
							<Tabs w="full" variant="soft-rounded" colorScheme="primary" isLazy lazyBehavior="unmount">
								<TabList>
									<Tab>Location</Tab>
									<Tab>Attendance</Tab>
									<Tab as={NavLink} to={`/map/searchUser?userId=${data.userById._id}&trail=1&from=${pathname}`}>
										Map
									</Tab>
								</TabList>
								<TabPanels w="full">
									<TabPanel as={VStack} w="full" maxW={{ base: "full", xl: "xl" }} align="stretch" spacing={4} px="0">
										<UserCurrentLocation userId={data.userById._id} />
										<UserPastLocations userId={data.userById._id} />
									</TabPanel>
									<TabPanel as={VStack} w="full" maxW={{ base: "full", xl: "xl" }} align="stretch" spacing={4} px="0">
										<UserActiveAttendance userId={data.userById._id} />
										<UserPastAttendances userId={data.userById._id} />
									</TabPanel>
								</TabPanels>
							</Tabs>
						)}
					</Stack>
				) : (
					<Center py="4">
						<Text fontSize="sm" fontWeight="semibold" color="error.500">
							Couldn&apos;t find the user.
						</Text>
					</Center>
				)}
			</VStack>
		</VStack>
	)
}

export default UserPage
