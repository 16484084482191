/* eslint-disable @typescript-eslint/indent */
import { cacheExchange as cE } from "@urql/exchange-graphcache"
import { betterQueryUpdate } from "../../utils"
import {
	ActivateAssetMutation,
	ActivateAssetMutationVariables,
	ActivateAssetTagOfAssetMutationVariables,
	ActivateAssetTagOfUserMutationVariables,
	ActivateTagOfUserMutationVariables,
	ActivateUserMutation,
	ActivateUserMutationVariables,
	ActiveAssetsInLastIntervalQueryVariables,
	ActiveAssetsQueryVariables,
	ActiveRestrictedEntryIdsDocument,
	ActiveRestrictedEntryIdsQuery,
	ActiveUsersInLastIntervalQueryVariables,
	ActiveUsersQueryVariables,
	AddReaderToZoneMutation,
	AddReaderToZoneMutationVariables,
	AlertFlowFragment,
	AlertFlowFragmentDoc,
	AlertFlowsByKeywordQueryVariables,
	AlertsByFilterQueryVariables,
	AlertTargetGroupFragment,
	AlertTargetGroupFragmentDoc,
	AlertTargetGroupsByKeywordQueryVariables,
	AllAssetTagsDocument,
	AllAssetTagsQuery,
	AllAssetTagsQueryVariables,
	AllDevicesDocument,
	AllDevicesQuery,
	AllDevicesQueryVariables,
	AllTagsDocument,
	AllTagsQuery,
	AllTagsQueryVariables,
	AssetCurrentLocationRecordDocument,
	AssetCurrentLocationRecordQuery,
	AssetCurrentLocationRecordQueryVariables,
	AssetFragment,
	AssetFragmentDoc,
	AssetLocationRecordsAtZoneDocument,
	AssetLocationRecordsAtZoneQuery,
	AssetLocationRecordsAtZoneQueryVariables,
	AssetLocationRecordStatus,
	AssetLocationRecordUpdateAtZoneSubscription,
	AssetLocationRecordUpdateAtZoneSubscriptionVariables,
	AssetLocationRecordUpdateSubscription,
	AssetLocationRecordUpdateSubscriptionVariables,
	AssetPastLocationRecordsDocument,
	AssetPastLocationRecordsQuery,
	AssetPastLocationRecordsQueryVariables,
	AssetsByKeywordQueryVariables,
	AssetsQueryVariables,
	AssetStatus,
	AssetTag,
	AssetTagFragment,
	AssetTagFragmentDoc,
	AssetTagsByFilterQueryVariables,
	AssignAssetTagToAssetMutation,
	AssignAssetTagToAssetMutationVariables,
	AssignAssetTagToUserMutation,
	AssignAssetTagToUserMutationVariables,
	AssignTagByDecimalIdMutation,
	AssignTagByDecimalIdMutationVariables,
	AssignTagMutation,
	AssignTagMutationVariables,
	AssignUserRoleMutation,
	AssignUserRoleMutationVariables,
	BuildingsByKeywordQueryVariables,
	CreateAssetTagMutation,
	CreateDeviceMutation,
	CreateReaderMutation,
	CreateTagMutation,
	DeactivateAssetMutation,
	DeactivateAssetMutationVariables,
	DeactivateAssetTagOfAssetMutationVariables,
	DeactivateAssetTagOfUserMutationVariables,
	DeactivateTagOfUserMutationVariables,
	DeactivateUserMutation,
	DeactivateUserMutationVariables,
	DepartmentsByKeywordQueryVariables,
	Device,
	DeviceFragment,
	DeviceFragmentDoc,
	DevicesByFilterQueryVariables,
	DeviceUpdateSubscription,
	DeviceUpdateSubscriptionVariables,
	ExtendedAlertFlowFragment,
	ExtendedAlertFlowFragmentDoc,
	ExtendedAlertTargetGroupFragment,
	ExtendedAlertTargetGroupFragmentDoc,
	ExtendedAsset,
	ExtendedAssetFragment,
	ExtendedAssetFragmentDoc,
	ExtendedAssetLocationRecordFragment,
	ExtendedAssetTag,
	ExtendedReportFragment,
	ExtendedTag,
	ExtendedUser,
	ExtendedUserFragment,
	ExtendedUserFragmentDoc,
	ExtendedUserLocationRecordFragment,
	ExtendedUserRole,
	ExtendedZone,
	ExtendedZoneFragment,
	ExtendedZoneFragmentDoc,
	ExtendedZoneGroupFragment,
	ExtendedZoneGroupFragmentDoc,
	ExtendUserRoleMutation,
	ExtendUserRoleMutationVariables,
	FinishAvatarUploadMutation,
	FinishAvatarUploadMutationVariables,
	FlushAssetTagOfAssetMutationVariables,
	FlushAssetTagOfUserMutationVariables,
	FlushTagMutationVariables,
	LoginMutation,
	LogoutMutation,
	MeDocument,
	MeQuery,
	MeUser,
	MeUserFragment,
	MeUserFragmentDoc,
	OverTimeActiveUsersInLastIntervalQueryVariables,
	PermissionsGroupsByKeywordQueryVariables,
	Reader,
	ReaderFragment,
	ReaderFragmentDoc,
	ReadersAtZoneDocument,
	ReadersAtZoneQuery,
	ReadersAtZoneQueryVariables,
	ReadersByFilterDocument,
	ReadersByFilterQuery,
	ReadersByFilterQueryVariables,
	ReaderUpdateAtZoneSubscription,
	ReaderUpdateAtZoneSubscriptionVariables,
	ReaderUpdateSubscription,
	ReaderUpdateSubscriptionVariables,
	RemoveCurrentUserRoleMutation,
	RemoveCurrentUserRoleMutationVariables,
	RemoveReaderFromZoneMutation,
	RemoveReaderFromZoneMutationVariables,
	ReportByIdDocument,
	ReportByIdQuery,
	ReportByIdQueryVariables,
	ReportsByKeywordQueryVariables,
	ReportUpdateSubscription,
	ReportUpdateSubscriptionVariables,
	RestrictedUserLocationRecordsUpdateSubscription,
	Tag,
	TagFragment,
	TagFragmentDoc,
	TagsByFilterQueryVariables,
	TagStatus,
	UpdateAlertFlowMutation,
	UpdateAlertFlowMutationVariables,
	UpdateAlertTargetGroupMutation,
	UpdateAlertTargetGroupMutationVariables,
	UpdateAssetMutation,
	UpdateAssetMutationVariables,
	UpdateDeviceMutation,
	UpdateDeviceMutationVariables,
	UpdateReaderMutation,
	UpdateReaderMutationVariables,
	UpdateUserMutation,
	UpdateUserMutationVariables,
	UpdateUserPermissionsGroupsMutation,
	UpdateUserPermissionsGroupsMutationVariables,
	UpdateVisitorDetailsMutation,
	UpdateVisitorDetailsMutationVariables,
	UpdateZoneGroupMutation,
	UpdateZoneGroupMutationVariables,
	User,
	UserCurrentLocationRecordDocument,
	UserCurrentLocationRecordQuery,
	UserCurrentLocationRecordQueryVariables,
	UserCurrentUserRoleDocument,
	UserCurrentUserRoleQuery,
	UserCurrentUserRoleQueryVariables,
	UserFragment,
	UserFragmentDoc,
	UserGroupsByKeywordQueryVariables,
	UserLocationRecordsAtZoneDocument,
	UserLocationRecordsAtZoneQuery,
	UserLocationRecordsAtZoneQueryVariables,
	UserLocationRecordStatus,
	UserLocationRecordUpdateAtZoneSubscription,
	UserLocationRecordUpdateAtZoneSubscriptionVariables,
	UserLocationRecordUpdateSubscription,
	UserLocationRecordUpdateSubscriptionVariables,
	UserPastAttendanceRecordsQueryVariables,
	UserPastLocationRecordsDocument,
	UserPastLocationRecordsQuery,
	UserPastLocationRecordsQueryVariables,
	UserPermissionsGroupsDocument,
	UserPermissionsGroupsQuery,
	UserPermissionsGroupsQueryVariables,
	UserRoleGroupsByKeywordQueryVariables,
	UsersByCurrentUserRoleGroupQueryVariables,
	UsersByDateAndTypeQueryVariables,
	UsersByDepartmentQueryVariables,
	UsersByKeywordQueryVariables,
	UsersByTypeQueryVariables,
	UsersQueryVariables,
	UserStatus,
	UserTypesByKeywordQueryVariables,
	VerifyEmailWithSecretCodeMutation,
	ZoneGroupFragment,
	ZoneGroupFragmentDoc,
	ZoneGroupsByKeywordQueryVariables,
	ZonesByKeywordQueryVariables,
} from "../generated"
import schema from "../generated/schema.json"
import { customPagination } from "../pagination"

export const cacheExchange = cE({
	schema: schema as any,
	keys: {
		Label: () => null,
		Phone: () => null,
		Email: () => null,
		Picture: () => null,
		PictureObject: () => null,
		Position: () => null,
		PaginatedUsersResponse: () => null,
		Tag: (data: any) => data._id,
		PaginatedUserTypesResponse: () => null,
		PaginatedZonesResponse: () => null,
		PaginatedZoneGroupsResponse: () => null,
		PaginatedAlertTargetGroupsResponse: () => null,
		PaginatedReportsResponse: () => null,
		PaginatedPermissionsGroupsResponse: () => null,
		PaginatedUserRoleGroupsResponse: () => null,
		PaginatedUserLocationRecordsResponse: () => null,
		PaginatedUserAttendanceRecordsResponse: () => null,
		PaginatedDepartmentsResponse: () => null,
		PaginatedReadersResponse: () => null,
		PaginatedTagsResponse: () => null,
		PaginatedAlertFlowsResponse: () => null,
		PaginatedAlertsResponse: () => null,
		ZoneWiseUsersResponse: () => null,
		ActiveUsersResponse: () => null,
		TypeWiseUsersResponse: () => null,
		ReportObject: () => null,
		VisitorDetails: () => null,
		ActiveRestrictedUserLocationRecordsResponse: () => null,
	},
	resolvers: {
		Query: {
			allUsers: customPagination("users", "PaginatedUsersResponse"),
			allAssets: customPagination("assets", "PaginatedAssetsResponse"),
			usersByKeyword: customPagination(
				"users",
				"PaginatedUsersResponse",
				(args: UsersByKeywordQueryVariables, vars: UsersByKeywordQueryVariables) =>
					args.keyword === vars.keyword &&
					args.typeId === vars.typeId &&
					args.roleType === vars.roleType &&
					args.visitorLevel === vars.visitorLevel &&
					args.status === vars.status &&
					(vars.since
						? new Date(vars.since).getTime() === (args.since ? new Date(args.since).getTime() : undefined)
						: args.since
						? new Date(args.since).getTime() === (vars.since ? new Date(vars.since).getTime() : undefined)
						: true) &&
					(vars.until
						? new Date(vars.until).getTime() === (args.until ? new Date(args.until).getTime() : undefined)
						: args.until
						? new Date(args.until).getTime() === (vars.until ? new Date(vars.until).getTime() : undefined)
						: true)
			),
			assetsByKeyword: customPagination("assets", "PaginatedAssetsResponse", (args: AssetsByKeywordQueryVariables, vars: AssetsByKeywordQueryVariables) => args.keyword === vars.keyword && args.status === vars.status),
			usersByType: customPagination("users", "PaginatedUsersResponse", (args: UsersByTypeQueryVariables, vars: UsersByTypeQueryVariables) => args.typeId === vars.typeId),
			usersByDepartment: customPagination("users", "PaginatedUsersResponse", (args: UsersByDepartmentQueryVariables, vars: UsersByDepartmentQueryVariables) => args.departmentId === vars.departmentId),
			usersByCurrentUserRoleGroup: customPagination(
				"users",
				"PaginatedUsersResponse",
				(args: UsersByCurrentUserRoleGroupQueryVariables, vars: UsersByCurrentUserRoleGroupQueryVariables) => args.userRoleGroupId === vars.userRoleGroupId
			),
			users: customPagination(
				"users",
				"PaginatedUsersResponse",
				(args: UsersQueryVariables, vars: UsersQueryVariables) => args.filter.departmentId === vars.filter.departmentId && args.filter.keyword === vars.filter.keyword && args.filter.userTypeId === vars.filter.userTypeId
			),
			assets: customPagination("assets", "PaginatedAssetsResponse", (args: AssetsQueryVariables, vars: AssetsQueryVariables) => args.filter.keyword === vars.filter.keyword),
			usersByDateAndType: customPagination(
				"users",
				"PaginatedUsersResponse",
				(args: UsersByDateAndTypeQueryVariables, vars: UsersByDateAndTypeQueryVariables) =>
					args.filter.userTypeId === vars.filter.userTypeId &&
					args.filter.keyword === vars.filter.keyword &&
					(vars.filter.since
						? new Date(vars.filter.since).getTime() === (args.filter.since ? new Date(args.filter.since).getTime() : undefined)
						: args.filter.since
						? new Date(args.filter.since).getTime() === (vars.filter.since ? new Date(vars.filter.since).getTime() : undefined)
						: true) &&
					(vars.filter.until
						? new Date(vars.filter.until).getTime() === (args.filter.until ? new Date(args.filter.until).getTime() : undefined)
						: args.filter.until
						? new Date(args.filter.until).getTime() === (vars.filter.until ? new Date(vars.filter.until).getTime() : undefined)
						: true)
			),
			allUserGroups: customPagination("userGroups", "PaginatedUserGroupsResponse"),
			userGroupsByKeyword: customPagination("userGroups", "PaginatedUserGroupsResponse", (args: UserGroupsByKeywordQueryVariables, vars: UserGroupsByKeywordQueryVariables) => args.keyword === vars.keyword),
			allUserTypes: customPagination("userTypes", "PaginatedUserTypesResponse"),
			userTypesByKeyword: customPagination("userTypes", "PaginatedUserTypesResponse", (args: UserTypesByKeywordQueryVariables, vars: UserTypesByKeywordQueryVariables) => args.keyword === vars.keyword),
			activeUsers: customPagination(
				"users",
				"PaginatedUsersResponse",
				(args: ActiveUsersQueryVariables, vars: ActiveUsersQueryVariables) => args.keyword === vars.keyword && args.typeId === vars.typeId && args.roleType === vars.roleType && args.status === vars.status
			),
			activeUsersInLastInterval: customPagination(
				"users",
				"PaginatedUsersResponse",
				(args: ActiveUsersInLastIntervalQueryVariables, vars: ActiveUsersInLastIntervalQueryVariables) =>
					args.keyword === vars.keyword && args.intervalInMinutes === vars.intervalInMinutes && args.typeId === vars.typeId && args.roleType === vars.roleType && args.status === vars.status
			),
			activeAssets: customPagination("assets", "PaginatedAssetsResponse", (args: ActiveAssetsQueryVariables, vars: ActiveAssetsQueryVariables) => args.keyword === vars.keyword && args.status === vars.status),
			activeAssetsInLastInterval: customPagination(
				"assets",
				"PaginatedAssetsResponse",
				(args: ActiveAssetsInLastIntervalQueryVariables, vars: ActiveAssetsInLastIntervalQueryVariables) => args.keyword === vars.keyword && args.intervalInMinutes === vars.intervalInMinutes && args.status === vars.status
			),
			overTimeActiveUsersInLastInterval: customPagination(
				"users",
				"PaginatedUsersResponse",
				(args: OverTimeActiveUsersInLastIntervalQueryVariables, vars: OverTimeActiveUsersInLastIntervalQueryVariables) =>
					args.timeLimit === vars.timeLimit && args.forToday === vars.forToday && args.keyword === vars.keyword && args.typeId === vars.typeId && args.roleType === vars.roleType && args.status === vars.status
			),
			todayRestrictedUserLocationRecords: customPagination("userLocationRecords", "PaginatedUserLocationRecordsResponse"),
			activeRestrictedUserLocationRecords: customPagination("userLocationRecords", "PaginatedUserLocationRecordsResponse"),
			allZones: customPagination("zones", "PaginatedZonesResponse"),
			zonesByKeyword: customPagination("zones", "PaginatedZonesResponse", (args: ZonesByKeywordQueryVariables, vars: ZonesByKeywordQueryVariables) => args.keyword === vars.keyword),
			allZoneGroups: customPagination("zoneGroups", "PaginatedZoneGroupsResponse"),
			zoneGroupsByKeyword: customPagination("zoneGroups", "PaginatedZoneGroupsResponse", (args: ZoneGroupsByKeywordQueryVariables, vars: ZoneGroupsByKeywordQueryVariables) => args.keyword === vars.keyword),
			allReports: customPagination("reports", "PaginatedReportsResponse"),
			reportsByKeyword: customPagination(
				"reports",
				"PaginatedReportsResponse",
				(args: ReportsByKeywordQueryVariables, vars: ReportsByKeywordQueryVariables) =>
					args.keyword === vars.keyword &&
					args.type === vars.type &&
					args.status === vars.status &&
					(args.startDate && vars.startDate ? new Date(args.startDate).getTime() === new Date(vars.startDate).getTime() : true) &&
					(args.endDate && vars.endDate ? new Date(args.endDate).getTime() === new Date(vars.endDate).getTime() : true)
			),
			allPermissionsGroups: customPagination("permissionsGroups", "PaginatedPermissionsGroupsResponse"),
			permissionsGroupsByKeyword: customPagination(
				"permissionsGroups",
				"PaginatedPermissionsGroupsResponse",
				(args: PermissionsGroupsByKeywordQueryVariables, vars: PermissionsGroupsByKeywordQueryVariables) => args.keyword === vars.keyword
			),
			allUserRoleGroups: customPagination("userRoleGroups", "PaginatedUserRoleGroupsResponse"),
			userRoleGroupsByKeyword: customPagination("userRoleGroups", "PaginatedUserRoleGroupsResponse", (args: UserRoleGroupsByKeywordQueryVariables, vars: UserRoleGroupsByKeywordQueryVariables) => args.keyword === vars.keyword),
			userPastLocationRecords: customPagination(
				"userLocationRecords",
				"PaginatedUserLocationRecordsResponse",
				(args: UserPastLocationRecordsQueryVariables, vars: UserPastLocationRecordsQueryVariables) => args.userId === vars.userId && new Date(args.date).getTime() === new Date(vars.date).getTime()
			),
			assetPastLocationRecords: customPagination(
				"assetLocationRecords",
				"PaginatedAssetLocationRecordsResponse",
				(args: AssetPastLocationRecordsQueryVariables, vars: AssetPastLocationRecordsQueryVariables) => args.assetId === vars.assetId && new Date(args.date).getTime() === new Date(vars.date).getTime()
			),
			userPastAttendanceRecords: customPagination(
				"userAttendanceRecords",
				"PaginatedUserAttendanceRecordsResponse",
				(args: UserPastAttendanceRecordsQueryVariables, vars: UserPastAttendanceRecordsQueryVariables) => args.userId === vars.userId && new Date(args.date).getTime() === new Date(vars.date).getTime()
			),
			allDepartments: customPagination("departments", "PaginatedDepartmentsResponse"),
			departmentsByKeyword: customPagination("departments", "PaginatedDepartmentsResponse", (args: DepartmentsByKeywordQueryVariables, vars: DepartmentsByKeywordQueryVariables) => args.keyword === vars.keyword),
			readersByFilter: customPagination(
				"readers",
				"PaginatedReadersResponse",
				(args: ReadersByFilterQueryVariables, vars: ReadersByFilterQueryVariables) => args.filter.keyword === vars.filter.keyword && args.filter.type === vars.filter.type && args.filter.isActive === vars.filter.isActive
			),
			allDevices: customPagination("devices", "PaginatedDevicesResponse"),
			devicesByFilter: customPagination(
				"devices",
				"PaginatedDevicesResponse",
				(args: DevicesByFilterQueryVariables, vars: DevicesByFilterQueryVariables) => args.filter.keyword === vars.filter.keyword && args.filter.type === vars.filter.type
			),
			allTags: customPagination("tags", "PaginatedTagsResponse"),
			allAssetTags: customPagination("assetTags", "PaginatedAssetTagsResponse"),
			tagsByFilter: customPagination(
				"tags",
				"PaginatedTagsResponse",
				(args: TagsByFilterQueryVariables, vars: TagsByFilterQueryVariables) => args.filter.keyword === vars.filter.keyword && args.filter.status === vars.filter.status && args.filter.batteryStatus === vars.filter.batteryStatus
			),
			assetTagsByFilter: customPagination(
				"aasetTags",
				"PaginatedAssetTagsResponse",
				(args: AssetTagsByFilterQueryVariables, vars: AssetTagsByFilterQueryVariables) =>
					args.filter.keyword === vars.filter.keyword && args.filter.status === vars.filter.status && args.filter.batteryStatus === vars.filter.batteryStatus
			),
			allAlertTargetGroups: customPagination("alertTargetGroups", "PaginatedAlertTargetGroupsResponse"),
			alertTargetGroupsByKeyword: customPagination(
				"alertTargetGroups",
				"PaginatedAlertTargetGroupsResponse",
				(args: AlertTargetGroupsByKeywordQueryVariables, vars: AlertTargetGroupsByKeywordQueryVariables) => args.keyword === vars.keyword
			),
			allAlerts: customPagination("alerts", "PaginatedAlertsResponse"),
			alertsByFilter: customPagination("alerts", "PaginatedAlertsResponse", (args: AlertsByFilterQueryVariables, vars: AlertsByFilterQueryVariables) => args.filter.type === vars.filter.type),
			allAlertFlows: customPagination("alertFlows", "PaginatedAlertFlowsResponse"),
			alertFlowsByKeyword: customPagination("alertFlows", "PaginatedAlertFlowsResponse", (args: AlertFlowsByKeywordQueryVariables, vars: AlertFlowsByKeywordQueryVariables) => args.keyword === vars.keyword),
			allBuildings: customPagination("buildings", "PaginatedBuildingsResponse"),
			buildingsByKeyword: customPagination("buildings", "PaginatedBuildingsResponse", (args: BuildingsByKeywordQueryVariables, vars: BuildingsByKeywordQueryVariables) => args.keyword === vars.keyword),
		},
	},
	updates: {
		Subscription: {
			userLocationRecordUpdateAtZone: (_result: UserLocationRecordUpdateAtZoneSubscription, variables: UserLocationRecordUpdateAtZoneSubscriptionVariables, cache) => {
				betterQueryUpdate<UserLocationRecordUpdateAtZoneSubscription, UserLocationRecordsAtZoneQuery>(
					cache,
					{
						query: UserLocationRecordsAtZoneDocument,
						variables: {
							zoneId: variables.zoneId,
						} as UserLocationRecordsAtZoneQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.userLocationRecordUpdateAtZone) {
							let userLocationRecordsAtZone = [...(query?.userLocationRecordsAtZone ?? [])] as ExtendedUserLocationRecordFragment[]

							const index = userLocationRecordsAtZone.findIndex((r) => String(r._id) === String(result.userLocationRecordUpdateAtZone._id))

							if (typeof index === "number" && index >= 0) {
								if (result.userLocationRecordUpdateAtZone.status === UserLocationRecordStatus.Final) {
									userLocationRecordsAtZone.splice(index, 1)
								} else {
									userLocationRecordsAtZone[index] = result.userLocationRecordUpdateAtZone
								}
							} else {
								userLocationRecordsAtZone = [result.userLocationRecordUpdateAtZone, ...userLocationRecordsAtZone]
							}

							return {
								...query,
								userLocationRecordsAtZone,
							}
						}
						return query
					}
				)
			},
			userLocationRecordUpdate: (_result: UserLocationRecordUpdateSubscription, variables: UserLocationRecordUpdateSubscriptionVariables, cache) => {
				betterQueryUpdate<UserLocationRecordUpdateSubscription, UserCurrentLocationRecordQuery>(
					cache,
					{
						query: UserCurrentLocationRecordDocument,
						variables: {
							userId: variables.userId,
						} as UserCurrentLocationRecordQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.userLocationRecordUpdate) {
							if (result.userLocationRecordUpdate.status === UserLocationRecordStatus.Final) {
								cache.updateQuery(
									{
										query: UserPastLocationRecordsDocument,
										variables: { userId: variables.userId } as UserPastLocationRecordsQueryVariables,
									},
									(data: any) => {
										return {
											...data,
											userPastLocationRecords: [result.userLocationRecordUpdate, ...(data.userPastLocationRecords || [])],
										} as UserPastLocationRecordsQuery
									}
								)

								return { ...query, userCurrentLocationRecord: null }
							}

							return { ...query, userCurrentLocationRecord: result.userLocationRecordUpdate }
						}
						return query
					}
				)
			},
			assetLocationRecordUpdateAtZone: (_result: AssetLocationRecordUpdateAtZoneSubscription, variables: AssetLocationRecordUpdateAtZoneSubscriptionVariables, cache) => {
				betterQueryUpdate<AssetLocationRecordUpdateAtZoneSubscription, AssetLocationRecordsAtZoneQuery>(
					cache,
					{
						query: AssetLocationRecordsAtZoneDocument,
						variables: {
							zoneId: variables.zoneId,
						} as AssetLocationRecordsAtZoneQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.assetLocationRecordUpdateAtZone) {
							let assetLocationRecordsAtZone = [...(query?.assetLocationRecordsAtZone ?? [])] as ExtendedAssetLocationRecordFragment[]

							const index = assetLocationRecordsAtZone.findIndex((r) => String(r._id) === String(result.assetLocationRecordUpdateAtZone._id))

							if (typeof index === "number" && index >= 0) {
								if (result.assetLocationRecordUpdateAtZone.status === AssetLocationRecordStatus.Final) {
									assetLocationRecordsAtZone.splice(index, 1)
								} else {
									assetLocationRecordsAtZone[index] = result.assetLocationRecordUpdateAtZone
								}
							} else {
								assetLocationRecordsAtZone = [result.assetLocationRecordUpdateAtZone, ...assetLocationRecordsAtZone]
							}

							return {
								...query,
								assetLocationRecordsAtZone,
							}
						}
						return query
					}
				)
			},
			assetLocationRecordUpdate: (_result: AssetLocationRecordUpdateSubscription, variables: AssetLocationRecordUpdateSubscriptionVariables, cache) => {
				betterQueryUpdate<AssetLocationRecordUpdateSubscription, AssetCurrentLocationRecordQuery>(
					cache,
					{
						query: AssetCurrentLocationRecordDocument,
						variables: {
							assetId: variables.assetId,
						} as AssetCurrentLocationRecordQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.assetLocationRecordUpdate) {
							if (result.assetLocationRecordUpdate.status === AssetLocationRecordStatus.Final) {
								cache.updateQuery(
									{
										query: AssetPastLocationRecordsDocument,
										variables: { assetId: variables.assetId } as AssetPastLocationRecordsQueryVariables,
									},
									(data: any) => {
										return {
											...data,
											assetPastLocationRecords: [result.assetLocationRecordUpdate, ...(data.assetPastLocationRecords || [])],
										} as AssetPastLocationRecordsQuery
									}
								)

								return { ...query, assetCurrentLocationRecord: null }
							}

							return { ...query, assetCurrentLocationRecord: result.assetLocationRecordUpdate }
						}
						return query
					}
				)
			},
			restrictedUserLocationRecordsUpdate: (_result: RestrictedUserLocationRecordsUpdateSubscription, _, cache) => {
				betterQueryUpdate<RestrictedUserLocationRecordsUpdateSubscription, ActiveRestrictedEntryIdsQuery>(
					cache,
					{
						query: ActiveRestrictedEntryIdsDocument,
					},
					_result,
					(result, query) => {
						if (result?.restrictedUserLocationRecordsUpdate) {
							const recordIds = [...query.activeRestrictedEntryIds.recordIds]
							let zoneWiseRecordIds = [...query.activeRestrictedEntryIds.zoneWiseRecordIds]

							const recordIdsIndex = recordIds.findIndex((uid) => uid === result.restrictedUserLocationRecordsUpdate._id)

							if (recordIdsIndex < 0) {
								recordIds.push(result.restrictedUserLocationRecordsUpdate._id)
							} else if (result.restrictedUserLocationRecordsUpdate.status === UserLocationRecordStatus.Final) {
								recordIds.splice(recordIdsIndex, 1)
							}

							zoneWiseRecordIds = zoneWiseRecordIds.map((z) => {
								const zonedRecordIds = [...z.recordIds]

								const index = zonedRecordIds.findIndex((uid) => uid === result.restrictedUserLocationRecordsUpdate._id)

								if (index < 0) {
									zonedRecordIds.push(result.restrictedUserLocationRecordsUpdate._id)
								} else if (result.restrictedUserLocationRecordsUpdate.status === UserLocationRecordStatus.Final) {
									zonedRecordIds.splice(index, 1)
								}

								return { ...z, recordIds: zonedRecordIds }
							})

							return {
								...query,
								activeRestrictedEntryIds: {
									...query.activeRestrictedEntryIds,
									recordIds,
									zoneWiseRecordIds,
								},
							}
						}
						return query
					}
				)
			},
			readerUpdateAtZone: (_result: ReaderUpdateAtZoneSubscription, variables: ReaderUpdateAtZoneSubscriptionVariables, cache) => {
				betterQueryUpdate<ReaderUpdateAtZoneSubscription, ReadersAtZoneQuery>(
					cache,
					{
						query: ReadersAtZoneDocument,
						variables: {
							zoneId: variables.zoneId,
						} as ReadersAtZoneQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.readerUpdateAtZone) {
							let readersAtZone = [...(query?.readersAtZone ?? [])] as ReaderFragment[]

							const index = readersAtZone.findIndex((r) => String(r._id) === String(result.readerUpdateAtZone._id))

							if (typeof index === "number" && index >= 0) {
								readersAtZone[index] = result.readerUpdateAtZone
							} else {
								readersAtZone = [result.readerUpdateAtZone, ...readersAtZone]
							}

							return {
								...query,
								readersAtZone,
							}
						}
						return query
					}
				)
			},
			readerUpdate: (result: ReaderUpdateSubscription, variables: ReaderUpdateSubscriptionVariables, cache) => {
				const reader = cache.readFragment<ReaderFragment>(ReaderFragmentDoc, { _id: variables.readerId } as Reader)

				if (reader) cache.writeFragment<ReaderFragment, { _id: string }>(ReaderFragmentDoc, { ...reader, ...result.readerUpdate }, { _id: variables.readerId })
			},
			deviceUpdate: (result: DeviceUpdateSubscription, variables: DeviceUpdateSubscriptionVariables, cache) => {
				const device = cache.readFragment<DeviceFragment>(DeviceFragmentDoc, { _id: variables.deviceId } as Device)

				if (device) cache.writeFragment<DeviceFragment, { _id: string }>(DeviceFragmentDoc, { ...device, ...result.deviceUpdate }, { _id: variables.deviceId })
			},
			reportUpdate: (_result: ReportUpdateSubscription, variables: ReportUpdateSubscriptionVariables, cache) => {
				betterQueryUpdate<ReportUpdateSubscription, ReportByIdQuery>(
					cache,
					{
						query: ReportByIdDocument,
						variables: {
							reportId: variables.reportId,
						} as ReportByIdQueryVariables,
					},
					_result,
					(result, query) => {
						if (result) {
							return {
								...query,
								reportById: {
									...query?.reportById,
									...{ ...(result.reportUpdate as ExtendedReportFragment), __typename: "ExtendedReport" },
								},
							}
						}

						return query
					}
				)
			},
		},
		Mutation: {
			login: (_result, _, cache) => {
				betterQueryUpdate<LoginMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.login.user) {
						return {
							me: result.login.user,
						}
					}

					return query
				})
			},
			logout: (result, _, cache) => {
				betterQueryUpdate<LogoutMutation, MeQuery>(cache, { query: MeDocument }, result, () => ({ me: null }))
			},
			verifyEmailWithSecretCode: (_result, _, cache) => {
				betterQueryUpdate<VerifyEmailWithSecretCodeMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.verifyEmailWithSecretCode && query?.me) {
						return {
							...query,
							me: { ...query.me, email: { ...query.me.email!, isVerified: true, verifiedAt: new Date() } },
						}
					}

					return query
				})
			},
			updateUser: (result: UpdateUserMutation, variables: UpdateUserMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe) {
					cache.writeFragment<MeUserFragment>(MeUserFragmentDoc, {
						...meQuery?.me,
						...(result.updateUser as MeUserFragment),
						__typename: "MeUser",
					})
				} else {
					cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, result.updateUser, { _id: variables.userId })
					cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...result.updateUser, __typename: "User" }, { _id: variables.userId })
				}
			},
			updateAsset: (result: UpdateAssetMutation, variables: UpdateAssetMutationVariables, cache) => {
				cache.writeFragment<ExtendedAssetFragment, { _id: string }>(ExtendedAssetFragmentDoc, result.updateAsset, { _id: variables.assetId })
				cache.writeFragment<AssetFragment, { _id: string }>(AssetFragmentDoc, { ...result.updateAsset, __typename: "Asset" }, { _id: variables.assetId })
			},
			updateVisitorDetails: (result: UpdateVisitorDetailsMutation, variables: UpdateVisitorDetailsMutationVariables, cache) => {
				cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, { ...result.updateVisitorDetails }, { _id: variables.userId })
			},
			activateUser: (result: ActivateUserMutation, variables: ActivateUserMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe) {
					cache.writeFragment<MeUserFragment>(MeUserFragmentDoc, {
						...meQuery?.me,
						...(result.activateUser as MeUserFragment),
						__typename: "MeUser",
					})
				} else {
					cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, result.activateUser, { _id: variables.userId })
					cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...result.activateUser, __typename: "User" }, { _id: variables.userId })
				}
			},
			deactivateUser: (result: DeactivateUserMutation, variables: DeactivateUserMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe) {
					cache.writeFragment<MeUserFragment>(MeUserFragmentDoc, {
						...meQuery?.me,
						...(result.deactivateUser as MeUserFragment),
						__typename: "MeUser",
					})
				} else {
					cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, result.deactivateUser, { _id: variables.userId })
					cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...result.deactivateUser, __typename: "User" }, { _id: variables.userId })
				}
			},
			activateAsset: (result: ActivateAssetMutation, variables: ActivateAssetMutationVariables, cache) => {
				cache.writeFragment<ExtendedAssetFragment, { _id: string }>(ExtendedAssetFragmentDoc, result.activateAsset, { _id: variables.assetId })
				cache.writeFragment<AssetFragment, { _id: string }>(AssetFragmentDoc, { ...result.activateAsset, __typename: "Asset" }, { _id: variables.assetId })
			},
			deactivateAsset: (result: DeactivateAssetMutation, variables: DeactivateAssetMutationVariables, cache) => {
				cache.writeFragment<ExtendedAssetFragment, { _id: string }>(ExtendedAssetFragmentDoc, result.deactivateAsset, { _id: variables.assetId })
				cache.writeFragment<AssetFragment, { _id: string }>(AssetFragmentDoc, { ...result.deactivateAsset, __typename: "Asset" }, { _id: variables.assetId })
			},
			updateUserPermissionsGroups: (result: UpdateUserPermissionsGroupsMutation, variables: UpdateUserPermissionsGroupsMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe) {
					cache.writeFragment<MeUserFragment>(MeUserFragmentDoc, {
						...(meQuery?.me as MeUser),
						permissionsGroupIds: result.updateUserPermissionsGroups.map((g) => g._id as string) as string[],
						permissionsGroups: result.updateUserPermissionsGroups || [],
						__typename: "MeUser",
					})
				} else {
					betterQueryUpdate<UpdateUserPermissionsGroupsMutation, UserPermissionsGroupsQuery>(
						cache,
						{ query: UserPermissionsGroupsDocument, variables: { userId: variables.userId } as UserPermissionsGroupsQueryVariables },
						result,
						(_result, query) => {
							if (_result) {
								return {
									...query,
									userPermissionsGroups: result.updateUserPermissionsGroups,
								}
							}

							return query
						}
					)
				}
			},
			assignUserRole: (result: AssignUserRoleMutation, variables: AssignUserRoleMutationVariables, cache) => {
				betterQueryUpdate<AssignUserRoleMutation, UserCurrentUserRoleQuery>(cache, { query: UserCurrentUserRoleDocument, variables: { userId: variables.userId } as UserCurrentUserRoleQueryVariables }, result, (_result, query) => {
					if (_result) {
						return {
							...query,
							userCurrentUserRole: result.assignUserRole,
						}
					}

					return query
				})
			},
			extendUserRole: (result: ExtendUserRoleMutation, variables: ExtendUserRoleMutationVariables, cache) => {
				betterQueryUpdate<ExtendUserRoleMutation, UserCurrentUserRoleQuery>(cache, { query: UserCurrentUserRoleDocument, variables: { userId: variables.userId } as UserCurrentUserRoleQueryVariables }, result, (_result, query) => {
					if (_result) {
						return {
							...query,
							userCurrentUserRole: { ...query?.userCurrentUserRole, ...(result.extendUserRole as ExtendedUserRole), __typename: "ExtendedUserRole" },
						}
					}

					return query
				})
			},
			removeCurrentUserRole: (result: RemoveCurrentUserRoleMutation, variables: RemoveCurrentUserRoleMutationVariables, cache) => {
				betterQueryUpdate<RemoveCurrentUserRoleMutation, UserCurrentUserRoleQuery>(
					cache,
					{ query: UserCurrentUserRoleDocument, variables: { userId: variables.userId } as UserCurrentUserRoleQueryVariables },
					result,
					(_result, query) => {
						if (_result) {
							return {
								...query,
								userCurrentUserRole: null,
							}
						}

						return query
					}
				)
			},
			activateTagOfUser: (_, variables: ActivateTagOfUserMutationVariables, cache) => {
				const tag = cache.readFragment<TagFragment, { userId: string }>(TagFragmentDoc, { assignedToUserId: variables.userId } as Tag)

				if (tag) {
					cache.writeFragment<TagFragment>(TagFragmentDoc, {
						...tag,
						status: TagStatus.Active,
						__typename: "Tag",
					})
				}
			},
			deactivateTagOfUser: (_, variables: DeactivateTagOfUserMutationVariables, cache) => {
				const tag = cache.readFragment<TagFragment>(TagFragmentDoc, { assignedToUserId: variables.userId } as Tag)

				if (tag) {
					cache.writeFragment<TagFragment>(TagFragmentDoc, {
						...tag,
						status: TagStatus.Inactive,
						__typename: "Tag",
					})
				}
			},
			flushTag: (_, variables: FlushTagMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe && meQuery?.me) {
					cache.writeFragment<MeUserFragment, { _id: string }>(MeUserFragmentDoc, { ...meQuery.me, tagId: null, tag: null }, { _id: variables.userId })
				} else {
					const user = cache.readFragment<ExtendedUserFragment>(ExtendedUserFragmentDoc, { _id: variables.userId } as ExtendedUser)

					if (!user) return

					cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, { ...user, tagId: null, tag: null }, { _id: variables.userId })
				}
			},
			assignTag: (result: AssignTagMutation, variables: AssignTagMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe && meQuery?.me) {
					cache.writeFragment<MeUserFragment, { _id: string }>(MeUserFragmentDoc, { ...meQuery.me, tagId: result.assignTag._id, tag: result.assignTag }, { _id: variables.userId })
				} else {
					const user = cache.readFragment<ExtendedUserFragment>(ExtendedUserFragmentDoc, { _id: variables.userId } as ExtendedUser)

					if (!user) return

					cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, { ...user, tagId: result.assignTag._id, tag: result.assignTag, status: UserStatus.Active }, { _id: variables.userId })
				}
			},
			activateAssetTagOfAsset: (_, variables: ActivateAssetTagOfAssetMutationVariables, cache) => {
				const assetTag = cache.readFragment<AssetTagFragment, { assetId: string }>(AssetTagFragmentDoc, { assignedToAssetId: variables.assetId } as AssetTag)

				if (assetTag) {
					cache.writeFragment<AssetTagFragment>(AssetTagFragmentDoc, {
						...assetTag,
						status: TagStatus.Active,
						__typename: "AssetTag",
					})
				}
			},
			deactivateAssetTagOfAsset: (_, variables: DeactivateAssetTagOfAssetMutationVariables, cache) => {
				const assetTag = cache.readFragment<AssetTagFragment>(AssetTagFragmentDoc, { assignedToAssetId: variables.assetId } as AssetTag)

				if (assetTag) {
					cache.writeFragment<AssetTagFragment>(AssetTagFragmentDoc, {
						...assetTag,
						status: TagStatus.Inactive,
						__typename: "AssetTag",
					})
				}
			},
			flushAssetTagOfAsset: (_, variables: FlushAssetTagOfAssetMutationVariables, cache) => {
				const asset = cache.readFragment<ExtendedAssetFragment>(ExtendedAssetFragmentDoc, { _id: variables.assetId } as ExtendedAsset)

				if (!asset) return

				cache.writeFragment<ExtendedAssetFragment, { _id: string }>(ExtendedAssetFragmentDoc, { ...asset, tagId: null, tag: null }, { _id: variables.assetId })
			},
			assignAssetTagToAsset: (result: AssignAssetTagToAssetMutation, variables: AssignAssetTagToAssetMutationVariables, cache) => {
				const asset = cache.readFragment<ExtendedAssetFragment>(ExtendedAssetFragmentDoc, { _id: variables.assetId } as ExtendedAsset)

				if (!asset) return

				cache.writeFragment<ExtendedAssetFragment, { _id: string }>(
					ExtendedAssetFragmentDoc,
					{ ...asset, tagId: result.assignAssetTagToAsset._id, tag: result.assignAssetTagToAsset, status: AssetStatus.Active },
					{ _id: variables.assetId }
				)
			},
			activateAssetTagOfUser: (_, variables: ActivateAssetTagOfUserMutationVariables, cache) => {
				const assetTag = cache.readFragment<AssetTagFragment, { assetId: string }>(AssetTagFragmentDoc, { assignedToUserId: variables.userId } as AssetTag)

				if (assetTag) {
					cache.writeFragment<AssetTagFragment>(AssetTagFragmentDoc, {
						...assetTag,
						status: TagStatus.Active,
						__typename: "AssetTag",
					})
				}
			},
			deactivateAssetTagOfUser: (_, variables: DeactivateAssetTagOfUserMutationVariables, cache) => {
				const assetTag = cache.readFragment<AssetTagFragment>(AssetTagFragmentDoc, { assignedToUserId: variables.userId } as AssetTag)

				if (assetTag) {
					cache.writeFragment<AssetTagFragment>(AssetTagFragmentDoc, {
						...assetTag,
						status: TagStatus.Inactive,
						__typename: "AssetTag",
					})
				}
			},
			flushAssetTagOfUser: (_, variables: FlushAssetTagOfUserMutationVariables, cache) => {
				const user = cache.readFragment<ExtendedUserFragment>(ExtendedUserFragmentDoc, { _id: variables.userId } as ExtendedUser)

				if (!user) return

				cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, { ...user, assetTagId: null, assetTag: null }, { _id: variables.userId })
			},
			assignAssetTagToUser: (result: AssignAssetTagToUserMutation, variables: AssignAssetTagToUserMutationVariables, cache) => {
				const user = cache.readFragment<ExtendedUserFragment>(ExtendedUserFragmentDoc, { _id: variables.userId } as ExtendedUser)

				if (!user) return

				cache.writeFragment<ExtendedUserFragment, { _id: string }>(
					ExtendedUserFragmentDoc,
					{ ...user, assetTagId: result.assignAssetTagToUser._id, assetTag: result.assignAssetTagToUser, status: UserStatus.Active },
					{ _id: variables.userId }
				)
			},
			assignTagByDecimalId: (result: AssignTagByDecimalIdMutation, variables: AssignTagByDecimalIdMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe && meQuery?.me) {
					cache.writeFragment<MeUserFragment, { _id: string }>(MeUserFragmentDoc, { ...meQuery.me, tagId: result.assignTagByDecimalId._id, tag: result.assignTagByDecimalId }, { _id: variables.userId })
				} else {
					const user = cache.readFragment<ExtendedUserFragment>(ExtendedUserFragmentDoc, { _id: variables.userId } as ExtendedUser)

					if (!user) return

					cache.writeFragment<ExtendedUserFragment, { _id: string }>(
						ExtendedUserFragmentDoc,
						{ ...user, tagId: result.assignTagByDecimalId._id, tag: result.assignTagByDecimalId, status: UserStatus.Active },
						{ _id: variables.userId }
					)
				}
			},
			addReaderToZone: (result: AddReaderToZoneMutation, variables: AddReaderToZoneMutationVariables, cache) => {
				const zone = cache.readFragment<ExtendedZoneFragment>(ExtendedZoneFragmentDoc, { _id: variables.zoneId } as ExtendedZone)

				if (!zone) return

				cache.writeFragment<ExtendedZoneFragment, { _id: string }>(ExtendedZoneFragmentDoc, { ...zone, readerIds: [result.addReaderToZone._id, ...(zone.readerIds || [])] }, { _id: variables.zoneId })

				betterQueryUpdate<AddReaderToZoneMutation, ReadersAtZoneQuery>(cache, { query: ReadersAtZoneDocument, variables: { zoneId: variables.zoneId } as ReadersAtZoneQueryVariables }, result, (_result, query) => {
					if (_result && query) {
						return {
							...query,
							readersAtZone: [_result.addReaderToZone, ...(query.readersAtZone || [])],
						}
					}

					return query
				})
			},
			removeReaderFromZone: (result: RemoveReaderFromZoneMutation, variables: RemoveReaderFromZoneMutationVariables, cache) => {
				const zone = cache.readFragment<ExtendedZoneFragment>(ExtendedZoneFragmentDoc, { _id: variables.zoneId } as ExtendedZone)

				if (!zone) return

				const readerIds = [...(zone.readerIds || [])]

				readerIds.splice(readerIds.indexOf(variables.readerId), 1)

				cache.writeFragment<ExtendedZoneFragment, { _id: string }>(ExtendedZoneFragmentDoc, { ...zone, readerIds }, { _id: variables.zoneId })

				betterQueryUpdate<AddReaderToZoneMutation, ReadersAtZoneQuery>(cache, { query: ReadersAtZoneDocument, variables: { zoneId: variables.zoneId } as ReadersAtZoneQueryVariables }, result, (_result, query) => {
					if (_result && query) {
						const readersAtZone = [...(query.readersAtZone || [])]

						readersAtZone.splice(
							readersAtZone.findIndex((r) => r._id === variables.readerId),
							1
						)

						return {
							...query,
							readersAtZone,
						}
					}

					return query
				})
			},
			createReader: (result: CreateReaderMutation, _, cache) => {
				betterQueryUpdate<CreateReaderMutation, ReadersByFilterQuery>(cache, { query: ReadersByFilterDocument, variables: { pagination: { limit: 20, page: 1 } } as ReadersByFilterQueryVariables }, result, (_result, query) => {
					if (_result && query) {
						return {
							...query,
							readersByFilter: { ...query.readersByFilter, readers: [result.createReader, ...query.readersByFilter.readers] },
						}
					}

					return query
				})
			},
			createTag: (result: CreateTagMutation, _, cache) => {
				betterQueryUpdate<CreateTagMutation, AllTagsQuery>(cache, { query: AllTagsDocument, variables: { pagination: { limit: 20, page: 1 } } as AllTagsQueryVariables }, result, (_result, query) => {
					if (_result && query) {
						return {
							...query,
							allTags: { ...query.allTags, tags: [result.createTag as ExtendedTag, ...query.allTags.tags] },
						}
					}

					return query
				})
			},
			createAssetTag: (result: CreateAssetTagMutation, _, cache) => {
				betterQueryUpdate<CreateAssetTagMutation, AllAssetTagsQuery>(cache, { query: AllAssetTagsDocument, variables: { pagination: { limit: 20, page: 1 } } as AllAssetTagsQueryVariables }, result, (_result, query) => {
					if (_result && query) {
						return {
							...query,
							allAssetTags: { ...query.allAssetTags, assetTags: [result.createAssetTag as ExtendedAssetTag, ...query.allAssetTags.assetTags] },
						}
					}

					return query
				})
			},
			createDevice: (result: CreateDeviceMutation, _, cache) => {
				betterQueryUpdate<CreateDeviceMutation, AllDevicesQuery>(cache, { query: AllDevicesDocument, variables: { pagination: { limit: 20, page: 1 } } as AllDevicesQueryVariables }, result, (_result, query) => {
					if (_result && query) {
						return {
							...query,
							allDevices: { ...query.allDevices, tags: [result.createDevice as Device, ...query.allDevices.devices] },
						}
					}

					return query
				})
			},
			updateAlertTargetGroup: (result: UpdateAlertTargetGroupMutation, variables: UpdateAlertTargetGroupMutationVariables, cache) => {
				cache.writeFragment<ExtendedAlertTargetGroupFragment, { _id: string }>(ExtendedAlertTargetGroupFragmentDoc, result.updateAlertTargetGroup, { _id: variables.alertTargetGroupId })
				cache.writeFragment<AlertTargetGroupFragment, { _id: string }>(AlertTargetGroupFragmentDoc, { ...result.updateAlertTargetGroup, __typename: "AlertTargetGroup" }, { _id: variables.alertTargetGroupId })
			},
			updateAlertFlow: (result: UpdateAlertFlowMutation, variables: UpdateAlertFlowMutationVariables, cache) => {
				cache.writeFragment<ExtendedAlertFlowFragment, { _id: string }>(ExtendedAlertFlowFragmentDoc, result.updateAlertFlow, { _id: variables.alertFlowId })
				cache.writeFragment<AlertFlowFragment, { _id: string }>(AlertFlowFragmentDoc, { ...(result.updateAlertFlow as AlertFlowFragment), __typename: "AlertFlow" }, { _id: variables.alertFlowId })
			},
			updateZoneGroup: (result: UpdateZoneGroupMutation, variables: UpdateZoneGroupMutationVariables, cache) => {
				cache.writeFragment<ExtendedZoneGroupFragment, { _id: string }>(ExtendedZoneGroupFragmentDoc, result.updateZoneGroup, { _id: variables.zoneGroupId })
				cache.writeFragment<ZoneGroupFragment, { _id: string }>(ZoneGroupFragmentDoc, { ...(result.updateZoneGroup as ZoneGroupFragment), __typename: "ZoneGroup" }, { _id: variables.zoneGroupId })
			},
			updateReader: (result: UpdateReaderMutation, variables: UpdateReaderMutationVariables, cache) => {
				cache.writeFragment<ReaderFragment, { _id: string }>(ReaderFragmentDoc, result.updateReader, { _id: variables.readerId })
			},
			updateDevice: (result: UpdateDeviceMutation, variables: UpdateDeviceMutationVariables, cache) => {
				cache.writeFragment<DeviceFragment, { _id: string }>(DeviceFragmentDoc, result.updateDevice, { _id: variables.deviceId })
			},
			finishAvatarUpload: (result: FinishAvatarUploadMutation, variables: FinishAvatarUploadMutationVariables, cache) => {
				const extendedUser = cache.readFragment<ExtendedUserFragment>(ExtendedUserFragmentDoc, { _id: variables.userId } as ExtendedUser)

				if (extendedUser) cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, { ...extendedUser, picture: result.finishAvatarUpload }, { _id: variables.userId })

				const user = cache.readFragment<UserFragment>(UserFragmentDoc, { _id: variables.userId } as User)

				if (user) cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...user, picture: result.finishAvatarUpload }, { _id: variables.userId })

				const meUser = cache.readFragment<MeUserFragment>(MeUserFragmentDoc, { _id: variables.userId } as MeUser)

				if (meUser) cache.writeFragment<MeUserFragment, { _id: string }>(MeUserFragmentDoc, { ...meUser, picture: result.finishAvatarUpload }, { _id: variables.userId })
			},
		},
	},
})
