/* eslint-disable @typescript-eslint/indent */
import { Center, Flex, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useZonesQuery } from "../../graphql"

import { ZoneItem } from "../zoneGroups/ZoneItem"

export const ZoneWiseAssets: React.FC = () => {
	const [{ data, fetching, error }] = useZonesQuery()

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">All zones</Heading>
			<Flex w="full" flexWrap="wrap">
				{fetching ? (
					<Center w="full" py="4">
						<VStack w="full" color="grayscale.label">
							<Text fontSize="sm">Loading zones</Text>
							<Spinner size="sm" />
						</VStack>
					</Center>
				) : error ? (
					<Center py="4">
						<Text fontSize="sm" fontWeight="semibold" color="error.500">
							{error.message.replace("[GraphQL] ", "")}
						</Text>
					</Center>
				) : data?.zones.length ? (
					data.zones.map((zone) => <ZoneItem key={zone._id} zone={zone} />)
				) : (
					<Center py="4">
						<Text fontSize="sm" fontWeight="semibold" color="error.500">
							Couldn&apos;t find any zones.
						</Text>
					</Center>
				)}
			</Flex>
		</VStack>
	)
}
