import { AspectRatio, Box, Center, HStack, Icon, IconButton, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { ChevronLeft, ChevronRight, Home, Map } from "react-feather"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { TileFragment, TileTypes, useTilesQuery } from "../../graphql"

export type TileSelectorProps = {
	tileId?: string
	type?: TileTypes
}

export const TileSelector: React.FC<TileSelectorProps> = ({ tileId, type = TileTypes.Outdoor }) => {
	const { pathname } = useLocation()

	const navigate = useNavigate()

	const [{ data }] = useTilesQuery({ variables: { type } })

	const [selectedTile, setSelectedTile] = useState<TileFragment>()

	useEffect(() => {
		if (pathname.startsWith("/map") && data?.tiles.length && !selectedTile) {
			if (tileId) {
				setSelectedTile(data.tiles.find((tile) => tile._id === tileId))
			} else {
				setSelectedTile(data.tiles[0])
				navigate(`/map/${data.tiles[0]._id}`)
			}
		}
	}, [data, selectedTile, pathname])

	const handleNextTileButton = () => {
		const index = data?.tiles.findIndex((tile) => tile._id === selectedTile?._id)

		if (typeof index !== "number" || index < 0) return data?.tiles.slice(0) || []

		const nextIndex = (index + 1) % data!.tiles.length
		const nextTile = data?.tiles[nextIndex]

		setSelectedTile(nextTile)
		navigate(`/map/${nextTile?._id}`)
	}

	const handlePrevTileButton = () => {
		const index = data?.tiles.findIndex((tile) => tile._id === selectedTile?._id)

		if (typeof index !== "number" || index < 0) return data?.tiles.slice(0) || []

		const prevIndex = (index - 1 + data!.tiles.length) % data!.tiles.length

		const prevTile = data?.tiles[prevIndex]

		setSelectedTile(prevTile)
		navigate(`/map/${prevTile?._id}`)
	}

	if (!data?.tiles.length) return <></>

	return (
		<>
			{!pathname.startsWith("/map") ? (
				<Box className="tiles-selector" pos="fixed" zIndex={10} top={12} right={4} w={24} transition="width .2s ease-in-out" bgColor="grayscale.off-white" rounded="2xl" overflow="hidden">
					<HStack justify="flex-start" overflow="auto">
						<AspectRatio
							w="full"
							minW={24}
							ratio={4 / 3}
							onClick={() => {
								setSelectedTile(data?.tiles.find((t) => t.embedsAllZones === true) || data?.tiles[0])
								navigate("/map")
							}}
							border="6px solid"
							borderColor="primary.400"
							rounded="2xl"
							cursor="pointer"
						>
							<Center w="full" bgColor="grayscale.off-white" rounded="lg">
								<Icon as={Map} fontSize="2xl" />
							</Center>
						</AspectRatio>
					</HStack>
				</Box>
			) : (
				<VStack>
					<IconButton pos="fixed" top={4} left={4} aria-label="home-btn" rounded="full" size="lg" colorScheme="primary" as={NavLink} to="/">
						<Icon as={Home} />
					</IconButton>
					{data.tiles.length > 1 && (
						<>
							<IconButton
								pos="fixed"
								aria-label="prev-btn"
								top="50%"
								left="4"
								transform="translateY(-50%)"
								size="lg"
								p="8"
								rounded="xl"
								bgColor="whiteAlpha.500"
								_hover={{ bgColor: "white", color: "black" }}
								transition="all .2s ease-in-out"
								onClick={handlePrevTileButton}
							>
								<Icon as={ChevronLeft} fontSize="4xl" />
							</IconButton>
							<IconButton
								pos="fixed"
								aria-label="next-btn"
								top="50%"
								right="4"
								transform="translateY(-50%)"
								size="lg"
								p="8"
								rounded="xl"
								bgColor="whiteAlpha.500"
								_hover={{ bgColor: "white", color: "black" }}
								transition="all .2s ease-in-out"
								onClick={handleNextTileButton}
							>
								<Icon as={ChevronRight} fontSize="4xl" />
							</IconButton>
						</>
					)}
				</VStack>
			)}
		</>
	)
}
