import { Center, chakra, Heading, HStack, Icon, Link, Spinner, Tag, Text, VStack } from "@chakra-ui/react"
import React, { useMemo, Fragment } from "react"
import { TrendingDown, TrendingUp } from "react-feather"
import { NavLink } from "react-router-dom"
import { useActiveUserIdsInLastIntervalQuery, useActiveUserIdsQuery, useTypeWiseActiveUsersQuery } from "../../graphql"
import { useIntervalContext } from "../../hooks"
import { LiveIndicator } from "../common"

export const CurrentlyActiveUsers: React.FC = () => {
	const [interval] = useIntervalContext()

	const [{ data, fetching, error }] = useActiveUserIdsQuery()
	const [{ data: typeWiseData }] = useTypeWiseActiveUsersQuery()
	const [{ data: pastData }] = useActiveUserIdsInLastIntervalQuery({ variables: { intervalInMinutes: interval } })

	const dodPercent = useMemo(() => {
		const CURRENT = data?.activeUserIds.userIds.length || 0
		const PREV = pastData?.activeUserIdsInLastInterval.length || 0

		const percent = (CURRENT / PREV) * 100 - 100

		return percent === Infinity ? 0 : percent
	}, [data, pastData])

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">Currently active users</Heading>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.activeUserIds ? (
				<VStack align="stretch">
					<HStack>
						<VStack align="stretch">
							<Link as={NavLink} to="/users/active">
								<Heading color="primary.400">{data.activeUserIds.userIds.length || 0}</Heading>
							</Link>

							<LiveIndicator />
						</VStack>
						{!!dodPercent && (
							<Tag px="4" rounded="full" colorScheme={dodPercent < 0 ? "red" : "green"}>
								{Math.abs(Math.round(dodPercent))}% <Icon as={dodPercent < 0 ? TrendingDown : TrendingUp} ml="2" />
							</Tag>
						)}
					</HStack>
					<Text fontSize="sm" color="grayscale.label">
						{typeWiseData?.typeWiseActiveUsers
							.sort((a, b) => a.label.name.charCodeAt(0) - b.label.name.charCodeAt(0))
							.map(({ typeId, label, userIds }, i, _) => (
								<Fragment key={typeId}>
									<Link key={typeId} as={NavLink} to={`/users/active?userType=${typeId}`}>
										<chakra.span fontWeight="semibold">
											{userIds.length || 0} <chakra.span fontWeight="normal">{label.name}</chakra.span>
										</chakra.span>
									</Link>
									{_.length - 1 !== i && <chakra.span fontWeight="semibold"> • </chakra.span>}
								</Fragment>
							))}
					</Text>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
