import loadable from "@loadable/component"
import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { Permissions } from "../components"
import { Scaffold } from "../components/common/Scaffold"
import { useMeQuery } from "../graphql"
import { checkForSomePermissions } from "../utils"

const DashboardPage = loadable(() => import("../pages/dashboard/Dashboard.page"))

const UsersPage = loadable(() => import("../pages/users/Users.page"))
const CreateUserPage = loadable(() => import("../pages/users/CreateUser.page"))
const UpdateUserProfilePage = loadable(() => import("../pages/users/UpdateUserProfile.page"))
const UpdateVisitorDetailsPage = loadable(() => import("../pages/users/UpdateVisitorDetails.page"))
const UpdateUserPermissionsGroupsPage = loadable(() => import("../pages/users/UpdateUserPermissionsGroups.page"))
const ExtendUserRolePage = loadable(() => import("../pages/users/ExtendUserRole.page"))
const AssignUserRolePage = loadable(() => import("../pages/users/AssignUserRole.page"))
const RemoveCurrentUserRolePage = loadable(() => import("../pages/users/RemoveCurrentUserRole.page"))
const ActivateUserPage = loadable(() => import("../pages/users/ActivateUser.page"))
const DeactivateUserPage = loadable(() => import("../pages/users/DeactivateUser.page"))
const UserPageWrapper = loadable(() => import("../components/auth/UserPageWrapper"))
const ProfilePage = loadable(() => import("../pages/Profile.page"))

const AssetsPage = loadable(() => import("../pages/assets/Assets.page"))
const CreateAssetPage = loadable(() => import("../pages/assets/CreateAsset.page"))
const AssetPage = loadable(() => import("../pages/assets/Asset.page"))
const UpdateAssetPage = loadable(() => import("../pages/assets/UpdateAsset.page"))
const ActivateAssetPage = loadable(() => import("../pages/assets/ActivateAsset.page"))
const DeactivateAssetPage = loadable(() => import("../pages/assets/DeactivateAsset.page"))

const AssetTagsPage = loadable(() => import("../pages/assetTags/AssetTags.page"))
const AssetTagPage = loadable(() => import("../pages/assetTags/AssetTag.page"))
const CreateAssetTagPage = loadable(() => import("../pages/assetTags/CreateAssetTag.page"))

const AssignAssetTagToAssetPage = loadable(() => import("../pages/assets/AssignAssetTagToAsset.page"))
const FlushAssetTagOfAssetPage = loadable(() => import("../pages/assets/FlushAssetTagOfAsset.page"))
const ActivateAssetTagOfAssetPage = loadable(() => import("../pages/assets/ActivateAssetTagOfAsset.page"))
const DeactivateAssetTagOfAssetPage = loadable(() => import("../pages/assets/DeactivateAssetTagOfAsset.page"))

const AssignAssetTagToUserPage = loadable(() => import("../pages/users/AssignAssetTagToUser.page"))
const FlushAssetTagOfUserPage = loadable(() => import("../pages/users/FlushAssetTagOfUser.page"))
const ActivateAssetTagOfUserPage = loadable(() => import("../pages/users/ActivateAssetTagOfUser.page"))
const DeactivateAssetTagOfUserPage = loadable(() => import("../pages/users/DeactivateAssetTagOfUser.page"))

const ActiveUsersInLastIntervalPage = loadable(() => import("../pages/users/ActiveUsersInLastInterval.page"))
const ActiveUsersPage = loadable(() => import("../pages/users/ActiveUsers.page"))
const OvertimeUsersPage = loadable(() => import("../pages/users/OvertimeUsers.page"))

const ActiveAssetsInLastIntervalPage = loadable(() => import("../pages/assets/ActiveAssetsInLastInterval.page"))
const ActiveAssetsPage = loadable(() => import("../pages/assets/ActiveAssets.page"))

const UserGroupsPage = loadable(() => import("../pages/userGroups/UserGroups.page"))
const UserGroupPage = loadable(() => import("../pages/userGroups/UserGroup.page"))
const CreateUserGroupPage = loadable(() => import("../pages/userGroups/CreateUserGroup.page"))

const AssignTagPage = loadable(() => import("../pages/users/AssignTag.page"))
const FlushTagPage = loadable(() => import("../pages/users/FlushTag.page"))
const ActivateTagOfUserPage = loadable(() => import("../pages/users/ActivateTagOfUser.page"))
const DeactivateTagOfUserPage = loadable(() => import("../pages/users/DeactivateTagOfUser.page"))

const UserTypesPage = loadable(() => import("../pages/userTypes/UserTypes.page"))
const UserTypePage = loadable(() => import("../pages/userTypes/UserType.page"))
const CreateUserTypePage = loadable(() => import("../pages/userTypes/CreateUserType.page"))

const MapPage = loadable(() => import("../pages/maps/Map.page"))
const MapUserSearchPage = loadable(() => import("../pages/maps/MapUserSearch.page"))
const MapAssetSearchPage = loadable(() => import("../pages/maps/MapAssetSearch.page"))
const TvPage = loadable(() => import("../pages/Tv.page"))

const ZonesPage = loadable(() => import("../pages/Zones.page"))
const ZonePage = loadable(() => import("../pages/Zone.page"))
const CreateZonePage = loadable(() => import("../pages/zones/CreateZone.page"))
const UpdateZonePage = loadable(() => import("../pages/zones/UpdateZone.page"))
const AddReaderToZonePage = loadable(() => import("../pages/zones/AddReaderToZone.page"))
const RemoveReaderFromZonePage = loadable(() => import("../pages/zones/RemoveReaderFromZone.page"))

const ZoneGroupsPage = loadable(() => import("../pages/ZoneGroups/ZoneGroups.page"))
const ZoneGroupPage = loadable(() => import("../pages/ZoneGroups/ZoneGroup.page"))
const CreateZoneGroupPage = loadable(() => import("../pages/ZoneGroups/CreateZoneGroup.page"))
const UpdateZoneGroupPage = loadable(() => import("../pages/ZoneGroups/UpdateZoneGroup.page"))

const PermissionsGroupsPage = loadable(() => import("../pages/permissionsGroups/PermissionsGroups.page"))
const PermissionsGroupPage = loadable(() => import("../pages/permissionsGroups/PermissionsGroup.page"))
const CreatePermissionsGroupPage = loadable(() => import("../pages/permissionsGroups/CreatePermissionsGroup.page"))

const GenerateUserMovementReportPage = loadable(() => import("../pages/reports/generate/GenerateUserMovementReport.page"))
const GenerateUserAttendanceReportPage = loadable(() => import("../pages/reports/generate/GenerateUserAttendanceReport.page"))
const GenerateReadersStatusLogsReportPage = loadable(() => import("../pages/reports/generate/GenerateReadersStatusLogsReport.page"))
const GenerateUserRestrictedEntriesReportPage = loadable(() => import("../pages/reports/generate/GenerateUserRestrictedEntriesReport.page"))
const GenerateZoneRestrictedEntriesReportPage = loadable(() => import("../pages/reports/generate/GenerateZoneRestrictedEntriesReport.page"))
const GenerateZoneGroupRestrictedEntriesReportPage = loadable(() => import("../pages/reports/generate/GenerateZoneGroupRestrictedEntriesReport.page"))
const GenerateDashboardReportPage = loadable(() => import("../pages/reports/generate/GenerateDashboardReport.page"))
const GenerateActiveUsersAtZonesReportPage = loadable(() => import("../pages/reports/generate/GenerateActiveUsersAtZonesReport.page"))
const GenerateActiveUsersAtZoneGroupsReportPage = loadable(() => import("../pages/reports/generate/GenerateActiveUsersAtZoneGroupsReport.page"))
const GenerateActiveUsersAtBuildingsReportPage = loadable(() => import("../pages/reports/generate/GenerateActiveUsersAtBuildingsReport.page"))
const GenerateVisitorReportPage = loadable(() => import("../pages/reports/generate/GenerateVisitorReport.page"))
const GenerateAssetMovementReportPage = loadable(() => import("../pages/reports/generate/GenerateAssetMovementReport.page"))

const ReportPage = loadable(() => import("../pages/reports/past/Report"))
const ReportsPage = loadable(() => import("../pages/reports/past/Reports"))

const ChangePassword = loadable(() => import("../pages/ChangePassword.page"))
const VerifyEmail = loadable(() => import("../pages/VerifyEmail.page"))

const UserRoleGroupsPage = loadable(() => import("../pages/userRoleGroups/UserRoleGroups.page"))
const CreateUserRoleGroupPage = loadable(() => import("../pages/userRoleGroups/CreateUserRoleGroup.page"))
const UserRoleGroupPage = loadable(() => import("../pages/userRoleGroups/UserRoleGroup.page"))
const UpdateUserRoleGroupPage = loadable(() => import("../pages/userRoleGroups/UpdateUserRoleGroup.page"))

const DepartmentsPage = loadable(() => import("../pages/departments/Departments.page"))
const DepartmentPage = loadable(() => import("../pages/departments/Department.page"))
const CreateDepartmentPage = loadable(() => import("../pages/departments/CreateDepartment.page"))

const ReadersPage = loadable(() => import("../pages/readers/Readers.page"))
const ReaderPage = loadable(() => import("../pages/readers/Reader.page"))
const CreateReaderPage = loadable(() => import("../pages/readers/CreateReader.page"))
const UpdateReaderPage = loadable(() => import("../pages/readers/UpdateReader.page"))

const DevicesPage = loadable(() => import("../pages/devices/Devices.page"))
const DevicePage = loadable(() => import("../pages/devices/Device.page"))
const CreateDevicePage = loadable(() => import("../pages/devices/CreateDevice.page"))
const UpdateDevicePage = loadable(() => import("../pages/devices/UpdateDevice.page"))

const TagsPage = loadable(() => import("../pages/tags/Tags.page"))
const TagPage = loadable(() => import("../pages/tags/Tag.page"))
const CreateTagPage = loadable(() => import("../pages/tags/CreateTag.page"))

const AlertTargetGroupsPage = loadable(() => import("../pages/alerts/targetGroups/AlertTargetGroups.page"))
const AlertTargetGroupPage = loadable(() => import("../pages/alerts/targetGroups/AlertTargetGroup.page"))
const CreateAlertTargetGroupPage = loadable(() => import("../pages/alerts/targetGroups/CreateAlertTargetGroup.page"))
const UpdateAlertTargetGroupPage = loadable(() => import("../pages/alerts/targetGroups/UpdateAlertTargetGroup.page"))

const AlertsPage = loadable(() => import("../pages/alerts/Alerts.page"))
const AlertPage = loadable(() => import("../pages/alerts/Alert.page"))

const AlertFlowsPage = loadable(() => import("../pages/alerts/flows/AlertFlows.page"))
const AlertFlowPage = loadable(() => import("../pages/alerts/flows/AlertFlow.page"))
const CreateAlertFlowPage = loadable(() => import("../pages/alerts/flows/CreateAlertFlow.page"))
const UpdateAlertFlowPage = loadable(() => import("../pages/alerts/flows/UpdateAlertFlow.page"))

const ServicesPage = loadable(() => import("../pages/services/Services.page"))
const ServicePage = loadable(() => import("../pages/services/Service.page"))
const CreateServicePage = loadable(() => import("../pages/services/CreateService.page"))

const BuildingsPage = loadable(() => import("../pages/buildings/Buildings.page"))
const BuildingPage = loadable(() => import("../pages/buildings/Building.page"))
const CreateBuildingPage = loadable(() => import("../pages/buildings/CreateBuilding.page"))
const UpdateBuildingPage = loadable(() => import("../pages/buildings/UpdateBuilding.page"))
const DeleteBuildingPage = loadable(() => import("../pages/buildings/DeleteBuilding.page"))
const BuildingFloorPage = loadable(() => import("../pages/buildings/BuildingFloor.page"))
const CreateBuildingFloorPage = loadable(() => import("../pages/buildings/CreateBuildingFloor.page"))
const UpdateBuildingFloorPage = loadable(() => import("../pages/buildings/UpdateBuildingFloor.page"))
const DeleteBuildingFloorPage = loadable(() => import("../pages/buildings/DeleteBuildingFloor.page"))

const TodayRestrictedEntriesPage = loadable(() => import("../pages/restrictedEntries/TodayRestrictedEntries.page"))
const ActiveRestrictedEntriesPage = loadable(() => import("../pages/restrictedEntries/ActiveRestrictedEntries.page"))

const NotFoundPage = loadable(() => import("../pages/NotFound.page"))
const UnauthorizedPage = loadable(() => import("../pages/Unauthorized.page"))

const LaptopVerificationPage = loadable(() => import("../pages/laptopVerification/LaptopVerification.page"))

export const RootRouter: React.FC = () => {
	const [{ data }] = useMeQuery()

	return (
		<Routes>
			<Route
				path="/tv"
				element={
					<Permissions tags={["tiles/read-tile", "tiles/read-tile-embedded-zone"]}>
						<TvPage />
					</Permissions>
				}
			/>
			{data?.me?.siteId === "65957f437404862120b26919" && (
				<Route
					path="/laptopVerification"
					element={
						<Permissions tags={["laptop-verification/laptop-verification"]}>
							<LaptopVerificationPage />
						</Permissions>
					}
				/>
			)}
			<Route
				path="/map"
				element={
					<Permissions tags={["tiles/read-tile", "tiles/read-tile-embedded-zone"]}>
						<MapPage />
					</Permissions>
				}
			/>
			<Route
				path="/map/searchUser"
				element={
					<Permissions tags={["tiles/read-tile", "tiles/read-tile-embedded-zone"]}>
						<MapUserSearchPage />
					</Permissions>
				}
			/>

			<Route
				path="/map/searchAsset"
				element={
					<Permissions tags={["tiles/read-tile", "tiles/read-tile-embedded-zone"]}>
						<MapAssetSearchPage />
					</Permissions>
				}
			/>

			<Route
				path="/map/:tileId"
				element={
					<Permissions tags={["tiles/read-tile", "tiles/read-tile-embedded-zone"]}>
						<MapPage />
					</Permissions>
				}
			/>
			<Route path="/" element={<Scaffold />}>
				<Route
					path="/"
					element={
						checkForSomePermissions(data?.me, "dashboard/read-disaster-management-dashboard", "dashboard/read-confidentiality-dashboard", "dashboard/read-asset-management-dashboard")[0] ? (
							<Permissions tags={["dashboard/read-disaster-management-dashboard", "dashboard/read-confidentiality-dashboard", "dashboard/read-asset-management-dashboard"]} checkType="or">
								<DashboardPage />
							</Permissions>
						) : (
							<Navigate to="/profile" />
						)
					}
				/>

				<Route
					path="/users"
					element={
						<Permissions tags={["users/read-short-term-user", "users/read-working-user", "users/read-admin-user"]} checkType="or">
							<UsersPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/create"
					element={
						<Permissions tags={["users/create-short-term-user", "users/create-working-user", "users/create-admin-user"]} checkType="or">
							<CreateUserPage />
						</Permissions>
					}
				/>

				<Route path="/users/:userId" element={<UserPageWrapper />} />
				<Route
					path="/users/:userId/updateProfile"
					element={
						<Permissions tags={["users/update-short-term-user", "users/update-working-user", "users/update-admin-user"]} checkType="or">
							<UpdateUserProfilePage />
						</Permissions>
					}
				/>

				<Route
					path="users/:userId/updateVisitorDetails"
					element={
						<Permissions tags={["users/update-short-term-user"]}>
							<UpdateVisitorDetailsPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/deactivate"
					element={
						<Permissions tags={["users/deactivate-short-term-user", "users/deactivate-working-user", "users/deactivate-admin-user"]} checkType="or">
							<DeactivateUserPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/activate"
					element={
						<Permissions tags={["users/activate-short-term-user", "users/activate-working-user", "users/activate-admin-user"]} checkType="or">
							<ActivateUserPage />
						</Permissions>
					}
				/>

				<Route path="/users/:userId/deactivateTag" element={<DeactivateTagOfUserPage />} />
				<Route path="/users/:userId/activateTag" element={<ActivateTagOfUserPage />} />
				<Route path="/users/:userId/assignTag" element={<AssignTagPage />} />
				<Route path="/users/:userId/flushTag" element={<FlushTagPage />} />

				<Route path="/users/:userId/deactivateAssetTag" element={<DeactivateAssetTagOfUserPage />} />
				<Route path="/users/:userId/activateAssetTag" element={<ActivateAssetTagOfUserPage />} />
				<Route path="/users/:userId/assignAssetTag" element={<AssignAssetTagToUserPage />} />
				<Route path="/users/:userId/flushAssetTag" element={<FlushAssetTagOfUserPage />} />

				<Route
					path="/users/:userId/updatePermissionsGroups"
					element={
						<Permissions tags={["permissions/update-short-term-user-permissions-group", "permissions/update-working-user-permissions-group", "permissions/update-admin-user-permissions-group"]} checkType="or">
							<UpdateUserPermissionsGroupsPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/extendUserRole"
					element={
						<Permissions tags={["user-roles/extend-short-term-role", "user-roles/extend-working-role"]} checkType="or">
							<ExtendUserRolePage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/assignUserRole"
					element={
						<Permissions tags={["user-roles/assign-short-term-role", "user-roles/assign-working-role"]} checkType="or">
							<AssignUserRolePage />
						</Permissions>
					}
				/>

				<Route
					path="/users/:userId/removeUserRole"
					element={
						<Permissions tags={["user-roles/remove-short-term-role", "user-roles/remove-working-role"]} checkType="or">
							<RemoveCurrentUserRolePage />
						</Permissions>
					}
				/>

				<Route
					path="/users/types"
					element={
						<Permissions tags={["user-types/read-short-term-user-type", "user-types/read-working-user-type", "user-types/read-admin-user-type"]} checkType="or">
							<UserTypesPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/types/create"
					element={
						<Permissions tags={["user-types/create-short-term-user-type", "user-types/create-working-user-type", "user-types/create-admin-user-type"]} checkType="or">
							<CreateUserTypePage />
						</Permissions>
					}
				/>

				<Route
					path="/users/types/:userTypeId"
					element={
						<Permissions tags={["user-types/read-short-term-user-type", "user-types/read-working-user-type", "user-types/read-admin-user-type"]} checkType="or">
							<UserTypePage />
						</Permissions>
					}
				/>

				<Route
					path="/users/groups"
					element={
						<Permissions tags={["users/read-user-group"]}>
							<UserGroupsPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/groups/create"
					element={
						<Permissions tags={["users/create-user-group"]}>
							<CreateUserGroupPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/groups/:userGroupId"
					element={
						<Permissions tags={["users/read-user-group"]}>
							<UserGroupPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/activeInLastInterval"
					element={
						<Permissions tags={["users/read-short-term-user", "users/read-working-user", "users/read-admin-user"]} checkType="or">
							<ActiveUsersInLastIntervalPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/active"
					element={
						<Permissions tags={["users/read-short-term-user", "users/read-working-user", "users/read-admin-user"]} checkType="or">
							<ActiveUsersPage />
						</Permissions>
					}
				/>

				<Route
					path="/users/overtime"
					element={
						<Permissions tags={["users/read-short-term-user", "users/read-working-user", "users/read-admin-user"]} checkType="or">
							<OvertimeUsersPage />
						</Permissions>
					}
				/>

				<Route path="/profile" element={<ProfilePage />} />

				<Route
					path="/assets"
					element={
						<Permissions tags={["assets/read-asset"]}>
							<AssetsPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/create"
					element={
						<Permissions tags={["assets/create-asset"]} checkType="or">
							<CreateAssetPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/:assetId"
					element={
						<Permissions tags={["assets/read-asset"]}>
							<AssetPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/:assetId/update"
					element={
						<Permissions tags={["assets/update-asset"]}>
							<UpdateAssetPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/:assetId/deactivate"
					element={
						<Permissions tags={["assets/deactivate-asset"]}>
							<DeactivateAssetPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/:assetId/activate"
					element={
						<Permissions tags={["assets/activate-asset"]}>
							<ActivateAssetPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/activeInLastInterval"
					element={
						<Permissions tags={["assets/read-asset"]}>
							<ActiveAssetsInLastIntervalPage />
						</Permissions>
					}
				/>

				<Route
					path="/assets/active"
					element={
						<Permissions tags={["assets/read-asset"]}>
							<ActiveAssetsPage />
						</Permissions>
					}
				/>

				<Route path="/assets/:assetId/assignTag" element={<AssignAssetTagToAssetPage />} />
				<Route path="/assets/:assetId/flushTag" element={<FlushAssetTagOfAssetPage />} />
				<Route path="/assets/:assetId/activateTag" element={<ActivateAssetTagOfAssetPage />} />
				<Route path="/assets/:assetId/deactivateTag" element={<DeactivateAssetTagOfAssetPage />} />

				<Route
					path="/assetTags"
					element={
						<Permissions tags={["asset-tags/read-asset-tag"]}>
							<AssetTagsPage />
						</Permissions>
					}
				/>
				<Route
					path="/assetTags/create"
					element={
						<Permissions tags={["asset-tags/create-asset-tag"]}>
							<CreateAssetTagPage />
						</Permissions>
					}
				/>
				<Route
					path="/assetTags/:tagId"
					element={
						<Permissions tags={["asset-tags/read-asset-tag"]}>
							<AssetTagPage />
						</Permissions>
					}
				/>

				<Route
					path="/restrictedEntries/today"
					element={
						<Permissions tags={["restricted-entries/read-restricted-entry"]} checkType="or">
							<TodayRestrictedEntriesPage />
						</Permissions>
					}
				/>

				<Route
					path="/restrictedEntries/active"
					element={
						<Permissions tags={["restricted-entries/read-restricted-entry"]} checkType="or">
							<ActiveRestrictedEntriesPage />
						</Permissions>
					}
				/>

				<Route
					path="/zones"
					element={
						<Permissions tags={["zones/read-zone"]}>
							<ZonesPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/create"
					element={
						<Permissions tags={["zones/create-zone"]}>
							<CreateZonePage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/:zoneId"
					element={
						<Permissions tags={["zones/read-zone"]}>
							<ZonePage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/:zoneId/update"
					element={
						<Permissions tags={["zones/update-zone"]}>
							<UpdateZonePage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/:zoneId/addReader"
					element={
						<Permissions tags={["readers/add-reader-to-zone"]}>
							<AddReaderToZonePage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/:zoneId/:readerId/remove"
					element={
						<Permissions tags={["readers/remove-reader-from-zone"]}>
							<RemoveReaderFromZonePage />
						</Permissions>
					}
				/>

				<Route
					path="/zones/groups"
					element={
						<Permissions tags={["zones/read-zone-group"]}>
							<ZoneGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/groups/create"
					element={
						<Permissions tags={["zones/create-zone-group"]}>
							<CreateZoneGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/groups/:zoneGroupId/update"
					element={
						<Permissions tags={["zones/update-zone-group"]}>
							<UpdateZoneGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/groups/:zoneGroupId"
					element={
						<Permissions tags={["zones/read-zone-group"]}>
							<ZoneGroupPage />
						</Permissions>
					}
				/>

				<Route
					path="/permissionsGroups"
					element={
						<Permissions tags={["permissions/read-permissions-tags", "permissions/read-permissions-group"]}>
							<PermissionsGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/permissionsGroups/create"
					element={
						<Permissions tags={["permissions/read-permissions-tags", "permissions/read-permissions-group", "permissions/create-permissions-group"]}>
							<CreatePermissionsGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/permissionsGroups/:permissionsGroupId"
					element={
						<Permissions tags={["permissions/read-permissions-tags", "permissions/read-permissions-group"]}>
							<PermissionsGroupPage />
						</Permissions>
					}
				/>

				<Route
					path="/userRoleGroups"
					element={
						<Permissions tags={["user-roles/read-working-role-group", "user-roles/read-short-term-role-group"]} checkType="or">
							<UserRoleGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/userRoleGroups/create"
					element={
						<Permissions tags={["user-roles/create-working-role-group", "user-roles/create-short-term-role-group"]} checkType="or">
							<CreateUserRoleGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/userRoleGroups/:userRoleGroupId"
					element={
						<Permissions tags={["user-roles/read-working-role-group", "user-roles/read-short-term-role-group"]} checkType="or">
							<UserRoleGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/userRoleGroups/:userRoleGroupId/update"
					element={
						<Permissions tags={["user-roles/update-working-role-group", "user-roles/update-short-term-role-group"]} checkType="or">
							<UpdateUserRoleGroupPage />
						</Permissions>
					}
				/>

				<Route path="/reports/past" element={<ReportsPage />} />
				<Route path="/reports/past/:reportId" element={<ReportPage />} />

				<Route
					path="/reports/generate/userMovement"
					element={
						<Permissions tags={["reports/create-user-location-records-report"]}>
							<GenerateUserMovementReportPage />
						</Permissions>
					}
				/>
				<Route
					path="/reports/generate/userAttendance"
					element={
						<Permissions tags={["reports/create-user-attendance-report"]}>
							<GenerateUserAttendanceReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/dashboard"
					element={
						<Permissions tags={["reports/create-dashboard-report"]}>
							<GenerateDashboardReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/readersStatusLogs"
					element={
						<Permissions tags={["reports/create-reader-status-logs-report"]}>
							<GenerateReadersStatusLogsReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/restrictedEntries/users"
					element={
						<Permissions tags={["reports/create-user-restricted-entry-report"]}>
							<GenerateUserRestrictedEntriesReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/restrictedEntries/zones"
					element={
						<Permissions tags={["reports/create-zone-restricted-entry-report"]}>
							<GenerateZoneRestrictedEntriesReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/activeUsersAtZones"
					element={
						<Permissions tags={["reports/create-active-users-report"]}>
							<GenerateActiveUsersAtZonesReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/activeUsersAtZoneGroups"
					element={
						<Permissions tags={["reports/create-active-users-report"]}>
							<GenerateActiveUsersAtZoneGroupsReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/activeUsersAtBuildings"
					element={
						<Permissions tags={["reports/create-active-users-report"]}>
							<GenerateActiveUsersAtBuildingsReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/visitor"
					element={
						<Permissions tags={["reports/create-visitor-report"]}>
							<GenerateVisitorReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/assetMovement"
					element={
						<Permissions tags={["reports/create-asset-location-records-report"]}>
							<GenerateAssetMovementReportPage />
						</Permissions>
					}
				/>

				<Route
					path="/reports/generate/restrictedEntries/zoneGroups"
					element={
						<Permissions tags={["reports/create-zone-group-restricted-entry-report"]}>
							<GenerateZoneGroupRestrictedEntriesReportPage />
						</Permissions>
					}
				/>

				<Route path="/changePassword" element={<ChangePassword />} />
				<Route path="/verifyEmail" element={<VerifyEmail />} />

				<Route
					path="/departments"
					element={
						<Permissions tags={["departments/read-department"]}>
							<DepartmentsPage />
						</Permissions>
					}
				/>
				<Route
					path="/departments/create"
					element={
						<Permissions tags={["departments/create-department"]}>
							<CreateDepartmentPage />
						</Permissions>
					}
				/>
				<Route
					path="/departments/:departmentId"
					element={
						<Permissions tags={["departments/read-department"]}>
							<DepartmentPage />
						</Permissions>
					}
				/>

				<Route
					path="/readers"
					element={
						<Permissions tags={["readers/read-reader"]}>
							<ReadersPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers/:readerId/update"
					element={
						<Permissions tags={["readers/update-reader"]}>
							<UpdateReaderPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers/:readerId"
					element={
						<Permissions tags={["readers/read-reader"]}>
							<ReaderPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers/create"
					element={
						<Permissions tags={["readers/create-reader"]}>
							<CreateReaderPage />
						</Permissions>
					}
				/>

				<Route
					path="/devices"
					element={
						<Permissions tags={["devices/read-device"]}>
							<DevicesPage />
						</Permissions>
					}
				/>
				<Route
					path="/devices/:deviceId/update"
					element={
						<Permissions tags={["devices/update-device"]}>
							<UpdateDevicePage />
						</Permissions>
					}
				/>
				<Route
					path="/devices/:deviceId"
					element={
						<Permissions tags={["devices/read-device"]}>
							<DevicePage />
						</Permissions>
					}
				/>
				<Route
					path="/devices/create"
					element={
						<Permissions tags={["devices/create-device"]}>
							<CreateDevicePage />
						</Permissions>
					}
				/>

				<Route
					path="/tags"
					element={
						<Permissions tags={["tags/read-tag"]}>
							<TagsPage />
						</Permissions>
					}
				/>
				<Route
					path="/tags/create"
					element={
						<Permissions tags={["tags/create-tag"]}>
							<CreateTagPage />
						</Permissions>
					}
				/>
				<Route
					path="/tags/:tagId"
					element={
						<Permissions tags={["tags/read-tag"]}>
							<TagPage />
						</Permissions>
					}
				/>

				<Route
					path="/alerts"
					element={
						<Permissions tags={["alerts/read-alert"]}>
							<AlertsPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/:alertId"
					element={
						<Permissions tags={["alerts/read-alert"]}>
							<AlertPage />
						</Permissions>
					}
				/>

				<Route
					path="/alerts/targetGroups"
					element={
						<Permissions tags={["alerts/read-alert-target-group"]}>
							<AlertTargetGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/targetGroups/create"
					element={
						<Permissions tags={["alerts/create-alert-target-group"]}>
							<CreateAlertTargetGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/targetGroups/:alertTargetGroupId"
					element={
						<Permissions tags={["alerts/read-alert-target-group"]}>
							<AlertTargetGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/targetGroups/:alertTargetGroupId/update"
					element={
						<Permissions tags={["alerts/update-alert-target-group"]}>
							<UpdateAlertTargetGroupPage />
						</Permissions>
					}
				/>

				<Route
					path="/alerts/flows"
					element={
						<Permissions tags={["alerts/read-alert-flow"]}>
							<AlertFlowsPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/flows/create"
					element={
						<Permissions tags={["alerts/create-alert-flow"]}>
							<CreateAlertFlowPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/flows/:alertFlowId"
					element={
						<Permissions tags={["alerts/read-alert-flow"]}>
							<AlertFlowPage />
						</Permissions>
					}
				/>
				<Route
					path="/alerts/flows/:alertFlowId/update"
					element={
						<Permissions tags={["alerts/update-alert-flow"]}>
							<UpdateAlertFlowPage />
						</Permissions>
					}
				/>

				<Route
					path="/services"
					element={
						<Permissions tags={["services/read-service"]}>
							<ServicesPage />
						</Permissions>
					}
				/>
				<Route
					path="/services/create"
					element={
						<Permissions tags={["services/create-service"]}>
							<CreateServicePage />
						</Permissions>
					}
				/>
				<Route
					path="/services/:serviceId"
					element={
						<Permissions tags={["services/read-service"]}>
							<ServicePage />
						</Permissions>
					}
				/>

				<Route
					path="/zones/buildings"
					element={
						<Permissions tags={["zones/read-building"]}>
							<BuildingsPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/create"
					element={
						<Permissions tags={["zones/create-building"]}>
							<CreateBuildingPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId/update"
					element={
						<Permissions tags={["zones/update-building"]}>
							<UpdateBuildingPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId/delete"
					element={
						<Permissions tags={["zones/delete-building"]}>
							<DeleteBuildingPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId"
					element={
						<Permissions tags={["zones/read-building"]}>
							<BuildingPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId/addFloor"
					element={
						<Permissions tags={["zones/create-building-floor"]}>
							<CreateBuildingFloorPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId/floors/:floorId"
					element={
						<Permissions tags={["zones/read-building-floor"]}>
							<BuildingFloorPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId/floors/:floorId/update"
					element={
						<Permissions tags={["zones/update-building-floor"]}>
							<UpdateBuildingFloorPage />
						</Permissions>
					}
				/>
				<Route
					path="/zones/buildings/:buildingId/floors/:floorId/delete"
					element={
						<Permissions tags={["zones/delete-building-floor"]}>
							<DeleteBuildingFloorPage />
						</Permissions>
					}
				/>
			</Route>
			<Route path="/unauthorized" element={<UnauthorizedPage />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	)
}
