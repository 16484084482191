import React from "react"
import { DashboardTypes } from "../../context/Dashboard/types"
import { ZoneFragment } from "../../graphql"
import { useDashboardContext } from "../../hooks"
import { ZoneItemRestrictedUserRecords } from "./ZoneItemRestrictedUserRecords"
import { ZoneItemUserRecords } from "./ZoneItemUserRecords"

export type ZoneItemUserRecordsProps = {
	zone: ZoneFragment
}

export const ZoneItemRecords: React.FC<ZoneItemUserRecordsProps> = ({ zone }) => {
	const { type } = useDashboardContext()

	return type === DashboardTypes.CONFIDENTIALITY ? <ZoneItemRestrictedUserRecords zone={zone} /> : <ZoneItemUserRecords zone={zone} />
}
