import { Select, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useDepartmentsQuery } from "../../graphql"

export type DepartmentSelectorProps = {
	onUpdate: (departmentId: string) => void
	value?: string
}

export const DepartmentSelector: React.FC<DepartmentSelectorProps> = ({ onUpdate, value }) => {
	const [{ data, error, fetching }] = useDepartmentsQuery()

	return (
		<VStack>
			{fetching ? (
				<Text>Fetching departments</Text>
			) : error ? (
				<VStack>
					<Text>Couldn&apos;t fetch departments</Text>
					<Text>{error.message.replace("[GraphQL] ", "")}</Text>
				</VStack>
			) : !data?.departments.length ? (
				<VStack>
					<Text>Couldn&apos;t fetch departments</Text>
				</VStack>
			) : (
				<Select flex="1" maxW="36" variant="filled" bgColor="grayscale.input-background" placeholder="Department" value={value} onChange={(e) => onUpdate(e.target.value)}>
					{data.departments.map((department) => (
						<option key={department._id} style={{ backgroundColor: "transparent" }} value={department._id}>
							{department.label.name}
						</option>
					))}
				</Select>
			)}
		</VStack>
	)
}
