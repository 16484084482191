import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import { ExtendedUserFragment, OverTimeActiveUsersInLastIntervalQueryVariables, useOverTimeActiveUsersInLastIntervalQuery, UserFragment, UserRoleTypes, UserStatus } from "../../graphql"
import { UsersList } from "./UsersList"

export type OvertimeUsersProps = {
	keyword: string
	typeId?: string
	roleType?: UserRoleTypes
	status?: UserStatus
	onUserClick?: (user: ExtendedUserFragment | UserFragment) => void
}

export const OvertimeUsers: React.FC<OvertimeUsersProps> = ({ keyword, typeId, roleType, status, onUserClick }) => {
	const storedValueInLocalStorage = localStorage.getItem("@LIGHTHOUSE_DEMO/OVERTIME_USERS_QUERY_TYPE")
	const forToday = storedValueInLocalStorage ? JSON.parse(storedValueInLocalStorage) : true

	const [filter, setFilter] = useState({ timeLimit: 12, forToday, typeId, roleType, keyword, status })

	const [pagination, setPagination] = useState<OverTimeActiveUsersInLastIntervalQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useOverTimeActiveUsersInLastIntervalQuery({
		variables: { ...filter, pagination },
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilter({ timeLimit: 12, forToday, typeId, roleType, keyword, status })
			setPagination((prev) => ({
				...prev,
				page: 1,
			}))
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [typeId, forToday, roleType, keyword, status])

	const nextPage = useCallback(() => {
		if (fetching) return

		if (data?.overTimeActiveUsersInLastInterval.hasNextPage && !fetching) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data, fetching])

	return (
		<VStack w="full" maxW="2xl" align="stretch">
			{fetching && !data?.overTimeActiveUsersInLastInterval ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading users</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.overTimeActiveUsersInLastInterval.users && data.overTimeActiveUsersInLastInterval.users.length ? (
				<VStack w="full" align="stretch">
					<Text>
						Showing {data.overTimeActiveUsersInLastInterval.users.length || 0} user
						{(data.overTimeActiveUsersInLastInterval.users.length || 0) > 1 ? "s" : ""}
					</Text>

					<UsersList users={data.overTimeActiveUsersInLastInterval.users} loadMore={nextPage} onUserClick={onUserClick} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more users</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any users.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
