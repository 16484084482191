import { useContext, useEffect } from "react"
import * as dashboardActions from "../context/Dashboard/actions"
import { DashboardContext } from "../context/Dashboard/context"
import { DashboardTypes } from "../context/Dashboard/types"
import { useMeQuery } from "../graphql"
import { checkForPermissions, checkForSomePermissions } from "../utils"

export const useDashboardContext = () => {
	const [state, dispatch] = useContext(DashboardContext)

	const [{ data }] = useMeQuery()

	useEffect(() => {
		const type = localStorage.getItem("@LIGHTHOUSE_DEMO/DASHBOARD_TYPE")

		if (type && Object.values(DashboardTypes).includes(type as DashboardTypes)) {
			dispatch(dashboardActions.setDashboardType(type as DashboardTypes))
		} else {
			if (checkForPermissions(data?.me, "dashboard/read-disaster-management-dashboard", "dashboard/read-confidentiality-dashboard", "dashboard/read-asset-management-dashboard")[0]) {
				dispatch(dashboardActions.setDashboardType(DashboardTypes.DISASTER_MANAGEMENT))
			} else if (checkForSomePermissions(data?.me, "dashboard/read-disaster-management-dashboard", "dashboard/read-confidentiality-dashboard", "dashboard/read-asset-management-dashboard")[0]) {
				if (checkForPermissions(data?.me, "dashboard/read-disaster-management-dashboard")[0]) {
					dispatch(dashboardActions.setDashboardType(DashboardTypes.DISASTER_MANAGEMENT))
				} else if (checkForPermissions(data?.me, "dashboard/read-confidentiality-dashboard")[0]) {
					dispatch(dashboardActions.setDashboardType(DashboardTypes.CONFIDENTIALITY))
				} else if (checkForPermissions(data?.me, "dashboard/read-asset-management-dashboard")[0]) {
					dispatch(dashboardActions.setDashboardType(DashboardTypes.ASSET_MANAGEMENT))
				}
			}
		}
	}, [])

	return { ...state, dispatch, ...dashboardActions }
}
