/* eslint-disable @typescript-eslint/indent */
import { Box, Center, Checkbox, HStack, Input, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useInViewport } from "react-in-viewport"
import { UsersQueryVariables, useUsersQuery } from "../../graphql"
import { UserTag } from "../common"
import { DepartmentSelector } from "./DepartmentSelector"
import { UserTypeSelector } from "./UserTypeSelector"

export type UserSelectorProps = {
	onUpdate: (userIds: string[], { keyword, departmentId, userTypeId, isAllSelected }: { keyword: string; departmentId: string; userTypeId: string; isAllSelected: boolean }) => void
	value: string[]
}

export const UserSelector: React.FC<UserSelectorProps> = ({ value, onUpdate }) => {
	const [variables, setVariables] = useState<UsersQueryVariables>({ filter: { departmentId: "", userTypeId: "", keyword: "" }, pagination: { limit: 20, page: 1 } })

	const nextPage = () => {
		if (data?.users.hasNextPage && !fetching) {
			setVariables((prev) => ({
				...prev,
				pagination: {
					...prev.pagination,
					page: (prev.pagination.page || 0) + 1,
				},
			}))
		}
	}
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			nextPage()
		}
	}, [inViewport])

	const [{ data, fetching, error }] = useUsersQuery({ variables })

	const isUserSelected = useCallback((userId: string) => value.includes(userId), [value])

	const isAllSelected = useMemo(() => data?.users.users.map((u) => u._id).every((uid) => value.includes(uid)), [data, value]) || false
	const isNoneSelected = useMemo(() => !value.length, [data, value])

	const [keyword, setKeyword] = useState("")
	const departmentId = variables.filter.departmentId || ""
	const userTypeId = variables.filter.userTypeId || ""

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setVariables((prev) => ({
				...prev,
				filter: {
					...prev.filter,
					keyword,
				},
				pagination: { ...prev.pagination, page: 1 },
			}))
			onUpdate([...value], { keyword, departmentId, userTypeId, isAllSelected })
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	const handleChange = (userId: string, isChecked: boolean) => {
		if (isChecked) {
			onUpdate([...value, userId], { keyword, departmentId, userTypeId, isAllSelected })
		} else {
			const _value = [...value]
			_value.splice(_value.indexOf(userId), 1)
			onUpdate(_value, { keyword, departmentId, userTypeId, isAllSelected })
		}
	}

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" flexWrap="wrap" justify="space-between">
				<Input flex="1" variant="filled" bgColor="grayscale.input-background" placeholder="Search" _placeholder={{ color: "grayscale.placeholer" }} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
				<UserTypeSelector
					value={variables.filter.userTypeId || ""}
					onUpdate={(userTypeId) => {
						setVariables((prev) => ({ ...prev, filter: { ...prev.filter, userTypeId }, pagination: { ...prev.pagination, page: 1 } }))
						onUpdate([...value], { keyword, departmentId, userTypeId, isAllSelected })
					}}
				/>
				<DepartmentSelector
					value={variables.filter.departmentId || ""}
					onUpdate={(departmentId) => {
						setVariables((prev) => ({ ...prev, filter: { ...prev.filter, departmentId }, pagination: { ...prev.pagination, page: 1 } }))
						onUpdate([...value], { keyword, departmentId, userTypeId, isAllSelected })
					}}
				/>
			</HStack>
			{fetching && !data?.users.users.length ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading users</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : !data?.users.users.length ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any user.
					</Text>
				</Center>
			) : (
				<VStack w="full" align="stretch" h="full" maxH={{ base: "sm", xl: "lg" }} overflowY="auto" spacing={0}>
					<Table>
						<Thead pos="sticky" top="0" w="full" zIndex={10} bgColor="white">
							<Tr>
								<Th>
									<Checkbox
										isChecked={isAllSelected}
										isIndeterminate={!isAllSelected && !isNoneSelected}
										onChange={(e) => {
											e.target.checked
												? onUpdate(
														data.users.users.map((u) => u._id),
														{ keyword, departmentId, userTypeId, isAllSelected: true }
												  )
												: onUpdate([], { keyword, departmentId, userTypeId, isAllSelected: false })
										}}
									>
										{!isAllSelected && !isNoneSelected && (
											<Text fontSize="xs" textTransform="lowercase">
												{value.length} selected
											</Text>
										)}
									</Checkbox>
								</Th>
								<Th>User</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data?.users.users.map((user) => (
								<Tr key={user._id}>
									<Td>
										<Checkbox isChecked={isUserSelected(user._id)} onChange={(e) => handleChange(user._id, e.target.checked)} />
									</Td>
									<Td>
										<UserTag user={user} size="sm" />
									</Td>
								</Tr>
							))}
							<Box w="full" h="2" ref={ref as any} />
						</Tbody>
					</Table>
					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more users</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			)}
		</VStack>
	)
}
