import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { ExtendedZoneFragment, UpdateZoneMutationVariables, useBuildingsQuery, useUpdateZoneMutation, ZoneTypes } from "../graphql"

type UpdateZoneFormValues = UpdateZoneMutationVariables["input"]

const validationSchema = yup.object<Record<keyof UpdateZoneFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().label("Description"),
	}),
	type: yup.string().oneOf(Object.values(ZoneTypes)).required().label("Type"),
	buildingId: yup.string().when("type", { is: ZoneTypes.Indoor, then: yup.string().required(), otherwise: yup.string().nullable() }).label("Building"),
	floorId: yup.string().when("type", { is: ZoneTypes.Indoor, then: yup.string().required(), otherwise: yup.string().nullable() }).label("Floor"),
})

export type UpdateZoneFormProps = {
	zone: ExtendedZoneFragment
}

export const UpdateZoneForm: React.FC<UpdateZoneFormProps> = ({ zone }) => {
	const initialValues: UpdateZoneFormValues = {
		label: { name: zone.label.name, description: zone.label.description },
		type: zone.type,
		buildingId: zone.buildingId,
		floorId: zone.floorId,
	}

	const [{ fetching }, updateZone] = useUpdateZoneMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: UpdateZoneFormValues) => {
		const { data, error } = await updateZone({ zoneId: zone._id, input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.updateZone) {
			navigate(`/zones/${data.updateZone._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<UpdateZoneFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: buildingsData, error: buildingsError, fetching: buildingsFetching }] = useBuildingsQuery()

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.type && formik.errors.type)}>
					<FormLabel fontWeight="bold">Type</FormLabel>

					<Select resize="vertical" variant="filled" bgColor="grayscale.input-background" placeholder="Select type" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("type")}>
						{Object.values(ZoneTypes).map((type) => (
							<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
								{type}
							</option>
						))}
					</Select>

					<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
				</FormControl>
				{formik.values.type === ZoneTypes.Indoor && (
					<>
						<FormControl isInvalid={Boolean(formik.touched.buildingId && formik.errors.buildingId)}>
							<FormLabel fontWeight="bold">Building</FormLabel>

							{buildingsFetching ? (
								<Text>Fetching buildings</Text>
							) : buildingsError ? (
								<VStack>
									<Text>Couldn&apos;t fetch zones</Text>
									<Text>{buildingsError.message.replace("[GraphQL] ", "")}</Text>
								</VStack>
							) : !buildingsData?.buildings.length ? (
								<VStack>
									<Text>Couldn&apos;t fetch buildings</Text>
								</VStack>
							) : (
								<Select resize="vertical" variant="filled" bgColor="grayscale.input-background" placeholder="Select building" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("buildingId")}>
									{buildingsData.buildings.map((building) => (
										<option key={building._id} style={{ backgroundColor: "transparent" }} value={building._id}>
											{building.label.name}
										</option>
									))}
								</Select>
							)}

							<FormErrorMessage>{formik.errors.buildingId}</FormErrorMessage>
						</FormControl>
						{buildingsData?.buildings.find((b) => b._id === formik.values.buildingId) && (
							<FormControl isInvalid={Boolean(formik.touched.floorId && formik.errors.floorId)}>
								<FormLabel fontWeight="bold">Floor</FormLabel>

								<Select resize="vertical" variant="filled" bgColor="grayscale.input-background" placeholder="Select building" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("floorId")}>
									{buildingsData?.buildings
										.find((b) => b._id === formik.values.buildingId)
										?.floors.map((floor) => (
											<option key={floor._id} style={{ backgroundColor: "transparent" }} value={floor._id}>
												{floor.label.name}
											</option>
										))}
								</Select>

								<FormErrorMessage>{formik.errors.floorId}</FormErrorMessage>
							</FormControl>
						)}
					</>
				)}
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Update
			</Button>
		</VStack>
	)
}
