import { Button, Divider, Heading, HStack, Icon, List, ListItem, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import { ChevronDown } from "react-feather"
import { NavLink } from "react-router-dom"
import { useLogoutMutation, useMeQuery } from "../../graphql"
import { Label } from "./Label"
import { Stat } from "./Stat"
import { UserTag } from "./UserTag"

export const AccountPopover: React.FC = () => {
	const [{ data }] = useMeQuery()

	const [{ fetching: loggingOut }, logout] = useLogoutMutation()

	const toast = useToast()

	const handleLogout = async () => {
		const { error } = await logout({})

		if (error) {
			return toast({ description: error.message.replace("GraphQL ", ""), status: "error" })
		}

		localStorage.removeItem("@LIGHTHOUSE_DEMO/DASHBOARD_TYPE")

		return toast({ description: "You've been logged out", status: "success" })
	}

	if (!data?.me) return <></>

	return (
		<Popover>
			<PopoverTrigger>
				<HStack p="4" _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer" justify="space-between">
					<UserTag user={data.me} />
					<Icon as={ChevronDown} />
				</HStack>
			</PopoverTrigger>
			<PopoverContent bgColor="whiteAlpha.400" backdropFilter="blur(24px)" border="none" _focus={{ boxShadow: "none", outline: "none" }}>
				<PopoverBody as={VStack} w="full" align="stretch">
					<VStack bgColor="grayscale.off-white" shadow="2xl" rounded="xl" align="stretch" spacing={0}>
						<VStack p="4" align="stretch">
							<UserTag user={data.me} />
						</VStack>
						<Divider />
						<VStack p="4" align="stretch">
							<Stat
								label="Site"
								value={
									<Label
										name={
											<Heading fontSize="sm" color="primary.500">
												{data.me.site.label.name}
											</Heading>
										}
										description={data.me.site.label.description}
									/>
								}
							/>
						</VStack>
						<Divider />
						<List w="full">
							<ListItem as={(props: any) => <Button {...props} as={NavLink} to="/profile" />} w="full" variant="ghost" colorScheme="primary" rounded="none">
								<Text w="full" textAlign="left" fontSize="xs">
									Profile
								</Text>
							</ListItem>
							<ListItem as={(props: any) => <Button {...props} as={NavLink} to="/changePassword" />} w="full" variant="ghost" colorScheme="primary" rounded="none">
								<Text w="full" textAlign="left" fontSize="xs">
									Change Password
								</Text>
							</ListItem>
						</List>
						<Divider />
						<List w="full">
							<ListItem as={Button} w="full" variant="ghost" colorScheme="error" rounded="none" roundedBottom="xl" isLoading={loggingOut} onClick={handleLogout}>
								<Text w="full" textAlign="left" fontSize="sm">
									Logout
								</Text>
							</ListItem>
						</List>
					</VStack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
